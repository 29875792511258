<template>
  <div id="app">
    <!-- 路由占位符 -->
    <keep-alive><router-view v-if="isRouterAlive"></router-view></keep-alive>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style>
.el-input {
  /* width: 130px; */
  /* min-width:200px */
}
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
</style>
