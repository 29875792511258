<template>
  <el-container class="home-container" ref='pie'>
    <!-- 头部区域 -->
    <el-header
      ><div>
        <img
          class="pic"
          src="../assets/2ef139de7f2e95fcd2dcd879eb9d3ce.png"
          alt=""
          
        />
        <span>云锄-后台管理系统</span>
      </div>
      <el-button type="success" @click="toLarge_screen" class="successbtn">大屏</el-button>
      <!-- <el-button type="success" @click="screen" >全屏</el-button> -->
      <el-button type="info" @click="logout">退出</el-button>
      </el-header
    >
    <!-- 页面主体区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '250px'" ref='pie'>
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <!-- 侧边栏菜单区域 -->
        <el-menu
          background-color="#fff"
          text-color="#000"
          active-text-color="#ffd04b"
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          :default-active="activePath"
        >
          <template v-for="item in menulist">
            <el-submenu
              v-if="item.subs && item.subs.length"
              :index="item.tab_id + ''"
              :key="item.tab_id"
            >
              <!-- <template slot="title"><i :class="item.icon"></i><span>{{item.name}}</span></template> -->
              <template slot="title">
                <!-- 图标 -->
                <!-- <i class="el-icon-location"></i> -->
                <!-- 文本 -->
                <span>{{ item.menu_name }}</span>
              </template>

              <!-- 二级菜单 -->
              <template v-for="itemChild in item.subs">
                <el-submenu
                  v-if="itemChild.sub && itemChild.sub.length"
                  :index="'/' + itemChild.tab + ''"
                  :key="itemChild.sys_id"
                >
                  <template slot="title"
                    >
                    <!-- <i class="el-icon-location"></i
                    > -->
                    <span>{{ itemChild.sub_name }}</span></template
                  >

                  <!-- 三级菜单 -->
                  <el-menu-item
                    v-for="itemChild_Child in itemChild.sub"
                    :index="'/' + itemChild_Child.tab_three + ''"
                    :key="itemChild_Child.sys_id"
                  >
                    <!-- <i class="el-icon-location"></i
                    > -->
                    <span slot="title">{{
                      itemChild_Child.sub_three
                    }}</span></el-menu-item
                  >
                </el-submenu>

                <el-menu-item
                  v-else
                  :index="'/' + itemChild.tab + ''"
                  :key="itemChild.sys_id"
                  >
                  <!-- <i class="el-icon-location"></i
                  > -->
                  <span slot="title">{{
                    itemChild.sub_name
                  }}</span></el-menu-item
                >
              </template>
            </el-submenu>

            <el-menu-item v-else :index="item.tab_id + ''" :key="item.tab_id"
              >
              <!-- <i class="el-icon-location"></i
              > -->
              <span slot="title">{{ item.menu_name }}</span></el-menu-item
            >
          </template>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主体 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// import screenfull from 'screenfull'
export default {
  data() {
    return {
      // 左侧菜单数据
      menulist: [],
      isCollapse: false,
      activePath: "",
      fullscreen: false
    };
  },
  activated() {
    this.getMenuList();
    this.activePath = window.sessionStorage.getItem("activePath");
  },
  mounted(){
	///add post
	/*  let params = {
        uid: sessionStorage.getItem("User_ID"),
      };
  this.$http({
        method: "post",
        url: "/mindex",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
		data:params,
		transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        console.log('res:',res.data);
        this.$message(res.data);
      });*/

console.log('refs.pie',this.$refs.pie);
  },
  methods: {
    
    logout() {
      window.sessionStorage.clear();
      this.$router.replace("./login");
    },
    // 获取所有菜单
    async getMenuList() {
		var obj = window.sessionStorage.getItem("roleObj");
		const formData = new FormData();
		if(obj){
			var role = JSON.parse(obj);
			// 可以添加其他表单字段
			formData.append('tab_id', role.role_tab_id);
			formData.append('user_phone', role.phone);
			formData.append('role', true);
		}
		this.$http({
		  method: "post",
		  url: "/mindex/" + window.sessionStorage.getItem("User_ID"),
		  headers: {
		    "Content-type": "multipart/form-data",
		  },
		  data: formData,
		}).then((res) => {
		  // console.log(res.data.meta.status);
		  // console.log(this.loginForm);
				console.log(res.data);
				this.menulist = res.data.data;
		});
		
  //    const { data: res } = await this.$http(
  //       "/mindex/" + window.sessionStorage.getItem("User_ID"),
		// headers: {
		//   "Content-type": "multipart/form-data",
		// },
		// data: formData
  //     );
  //     console.log(res);
  //     this.menulist = res.data;
    },
    // 菜单折叠
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    // 保存链接激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem("activePath", activePath);
      this.activePath = activePath;
    },
    toLarge_screen(){
      this.$router.push('/Large_screen')
    },
    screen() {
   // 如果不允许进入全屏，发出不允许提示
   if (!screenfull.enabled) {
    this.$message("您的浏览器不能全屏");
    return false;
   }
   screenfull.toggle();
   this.$message.success("全屏啦");
  },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-aside{
  background-color: #fff;
}
.successbtn{
  position: absolute;
  right: 100px;
}
.el-header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-size: 20px;
  border-bottom: 1px solid #C0C0C0;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
}
.el-aside {
  background-color: #fff;
  .el-menu {
    border-right: none;
  }
}
.el-main {
  background-color: #fff;
  border-left:1px solid #C0C0C0;
}
.home-container {
  height: 100%;
}
.pic {
  width: 100px;
  height: 50px;
}
.toggle-button {
  background-color: #fff;
  font-size: 10px;
  line-height: 24px;
  color: #000;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
</style>
