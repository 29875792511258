<template>
  <div>
     
     <img src="../assets/2ef139de7f2e95fcd2dcd879eb9d3ce.png" alt="" class="pic">
  </div>
</template>

<script>
export default {

}
</script>

<style>

.pic{
  width: 100%;
  height: 100%;
}
</style>