<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>大蒜</el-breadcrumb-item>
      <el-breadcrumb-item>大蒜生产管理历-技术1</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="0">
        <el-col :span="8">
          <el-input  placeholder="请输入内容"
            v-model="queryinfo.query"
            clearable
            @clear="gettechnicallist">
            <el-button
                 slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button> </el-input
        ></el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加</el-button
          >
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table :data="technicallist" border>
        <!-- <el-table-column type="index"></el-table-column> -->
        <el-table-column label="序号" width="70px">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          label="技术要点"
          prop="data.garlic_product_man_id[4]"
          :resizable="true"
        ></el-table-column>
        <el-table-column label="步骤1" prop="seed_desc01">
          <el-table-column
            label="第1点"
            prop="data.seed_desc01_select01"
            :show-overflow-tooltip="true"
            width="140px"
          ></el-table-column>
          <el-table-column
            label="第2点"
            prop="data.seed_desc01_select02"
            :show-overflow-tooltip="true"
            width="140px"
          ></el-table-column>
          <el-table-column
            label="第3点"
            prop="data.seed_desc01_select03"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="第4点"
            prop="data.seed_desc01_select04"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="第5点"
            prop="data.seed_desc01_select05"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="第6点"
            prop="data.seed_desc01_select06"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="步骤2" prop="seed_desc02">
          <el-table-column
            label="第1点"
            prop="data.seed_desc02_select01"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="第2点"
            prop="data.seed_desc02_select02"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="第3点"
            prop="data.seed_desc02_select03"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="第4点"
            prop="data.seed_desc02_select04"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="第5点"
            prop="data.seed_desc02_select05"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="第6点"
            prop="data.seed_desc02_select06"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="步骤3" prop="seed_desc03">
          <el-table-column
            label="第1点"
            prop="data.seed_desc03_select01"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="第2点"
            prop="data.seed_desc03_select02"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="第3点"
            prop="data.seed_desc03_select03"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="第4点"
            prop="data.seed_desc03_select04"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="第5点"
            prop="data.seed_desc03_select05"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="第6点"
            prop="data.seed_desc03_select06"
            width="140px"
            :show-overflow-tooltip="true"
          ></el-table-column>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="150px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.data.id)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removebyid(scope.row.data.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加 -->
      <el-dialog
        title="添加"
        :visible.sync="addDialogVisible"
        width="50%"
        @close="addDialogclosed"
      >
        <el-form
          :model="addForm"
          :rules="addFormrules"
          ref="addFormRef"
          label-width="200px"
        >
          <!-- <el-form-item label="注册时间" prop="add_time">
            <el-input v-model="addForm.add_time"></el-input>
          </el-form-item> -->
          <el-form-item label="技术要点" prop="garlic_product_man_id">
            <!-- <el-input v-model="addForm.garlic_product_man_id"></el-input> -->
            <el-select v-model="value1" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item[0]"
                :label="item[1]"
                :value="item[0]"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="步骤1" prop="seed_desc01">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc01"
            ></el-input>
          </el-form-item>
          <el-form-item label="第1点" prop="seed_desc01_select01">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc01_select01"
            ></el-input>
          </el-form-item>

          <el-form-item label="第2点" prop="seed_desc01_select02">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc01_select02"
            ></el-input>
          </el-form-item>

          <el-form-item label="第3点" prop="seed_desc01_select03">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc01_select03"
            ></el-input>
          </el-form-item>

          <el-form-item label="第4点" prop="seed_desc01_select04">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc01_select04"
            ></el-input>
          </el-form-item>

          <el-form-item label="第5点" prop="seed_desc01_select05">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc01_select05"
            ></el-input>
          </el-form-item>

          <el-form-item label="第6点" prop="seed_desc01_select06">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc01_select06"
            ></el-input>
          </el-form-item>
          <el-form-item label="步骤2" prop="seed_desc02">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc02"
            ></el-input>
          </el-form-item>
          <el-form-item label="第1点" prop="seed_desc02_select01">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc02_select01"
            ></el-input>
          </el-form-item>

          <el-form-item label="第2点" prop="seed_desc02_select02">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc02_select02"
            ></el-input>
          </el-form-item>

          <el-form-item label="第3点" prop="seed_desc02_select03">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc02_select03"
            ></el-input>
          </el-form-item>

          <el-form-item label="第4点" prop="seed_desc02_select04">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc02_select04"
            ></el-input>
          </el-form-item>

          <el-form-item label="第5点" prop="seed_desc02_select05">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc02_select05"
            ></el-input>
          </el-form-item>

          <el-form-item label="第6点" prop="seed_desc02_select06">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc02_select06"
            ></el-input>
          </el-form-item>
          <el-form-item label="步骤3" prop="seed_desc03">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc03"
            ></el-input>
          </el-form-item>
          <el-form-item label="第1点" prop="seed_desc03_select01">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc03_select01"
            ></el-input>
          </el-form-item>

          <el-form-item label="第2点" prop="seed_desc03_select02">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc03_select02"
            ></el-input>
          </el-form-item>

          <el-form-item label="第3点" prop="seed_desc03_select03">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc03_select03"
            ></el-input>
          </el-form-item>

          <el-form-item label="第4点" prop="seed_desc03_select04">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc03_select04"
            ></el-input>
          </el-form-item>

          <el-form-item label="第5点" prop="seed_desc03_select05">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc03_select05"
            ></el-input>
          </el-form-item>

          <el-form-item label="第6点" prop="seed_desc03_select06">
            <el-input
              type="textarea"
              autosize
              v-model="addForm.seed_desc03_select06"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="add()">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改 -->
      <el-dialog
        title="修改"
        :visible.sync="editdialogVisible"
        width="50%"
        @close="editDialogClosed"
      >
        <el-form
          :model="editfrom"
          :rules="editfromrules"
          ref="editfromref"
          label-width="170px"
        >
          <el-form-item label="技术要点">
            <!-- <el-input v-model="editfrom.garlic_product_man_id[4]"></el-input> -->
            <el-select
              v-model="value1"
              :placeholder="editfrom.garlic_product_man_id[4]"
            >
              <el-option
                v-for="item in options"
                :key="item[0]"
                :label="item[1]"
                :value="item[0]"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="步骤1">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc01[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第1点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc01_select01[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第2点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc01_select02[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第3点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc01_select03[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第4点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc01_select04[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第5点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc01_select05[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第6点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc01_select06[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="步骤2">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc02[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第1点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc02_select01[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第2点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc02_select02[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第3点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc02_select03[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第4点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc02_select04[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第5点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc02_select05[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第6点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc02_select06[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="步骤3">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc03[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第1点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc03_select01[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第2点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc03_select02[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第3点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc03_select03[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第4点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc03_select04[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第5点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc03_select05[1]"
            ></el-input>
          </el-form-item>
          <el-form-item label="第6点">
            <el-input
              type="textarea"
              autosize
              v-model="editfrom.seed_desc03_select06[1]"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editdialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editfrominfo">确 定</el-button>
        </span>
      </el-dialog>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryinfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
      },
      total: 0,
      technicallist: [],
      editfromrules: {},
      editfrom: {
        // a:this.garlic_product_man_id[1],
        garlic_product_man_id: "",
        seed_desc01: "",
        seed_desc01_select02: "",
        seed_desc01_select03: "",
        seed_desc01_select04: "",
        seed_desc01_select05: "",
        seed_desc01_select06: "",
        seed_desc01_select01: "",
        seed_desc02: "",
        seed_desc02_select02: "",
        seed_desc02_select03: "",
        seed_desc02_select04: "",
        seed_desc02_select05: "",
        seed_desc02_select06: "",
        seed_desc02_select01: "",
        seed_desc03: "",
        seed_desc03_select02: "",
        seed_desc03_select03: "",
        seed_desc03_select04: "",
        seed_desc03_select05: "",
        seed_desc03_select06: "",
        seed_desc03_select01: "",
        add_tab: "technical_features",
      },
      editdialogVisible: false,
      addDialogVisible: false,
      suanlist: [],
      addForm: {
        garlic_product_man_id: "",
        seed_desc01: "",
        seed_desc01_select02: "",
        seed_desc01_select03: "",
        seed_desc01_select04: "",
        seed_desc01_select05: "",
        seed_desc01_select06: "",
        seed_desc01_select01: "",
        seed_desc02: "",
        seed_desc02_select02: "",
        seed_desc02_select03: "",
        seed_desc02_select04: "",
        seed_desc02_select05: "",
        seed_desc02_select06: "",
        seed_desc02_select01: "",
        seed_desc03: "",
        seed_desc03_select02: "",
        seed_desc03_select03: "",
        seed_desc03_select04: "",
        seed_desc03_select05: "",
        seed_desc03_select06: "",
        seed_desc03_select01: "",
        add_tab: "technical_features",
      },
      addFormrules: {
        // features: [
        //   { required: true, message: "请输入主要特点", trigger: "blur" },
        // ],
        // img01: [{ required: true, message: "请输入示意图1", trigger: "blur" }],
        // img02: [{ required: true, message: "请输入示意图2", trigger: "blur" }],
        // month_desc: [
        //   { required: true, message: "请输入月份描述", trigger: "blur" },
        // ],
        // month_end: [
        //   {
        //     required: true,
        //     message: "请输入对应月份结束时间",
        //     trigger: "blur",
        //   },
        // ],
        // month_start: [
        //   {
        //     required: true,
        //     message: "请输入对应月份开始时间",
        //     trigger: "blur",
        //   },
        // ],
        // phenological_period: [
        //   { required: true, message: "请输入物候期", trigger: "blur" },
        // ],
        // technical_feature: [
        //   { required: true, message: "请输入技术要点", trigger: "blur" },
        // ],
      },
      options: [],
      op: [
        {
          0: "选项1",
          1: "黄金糕",
        },
        {
          0: "选项2",
          1: "双皮奶",
        },
        {
          0: "选项3",
          1: "蚵仔煎",
        },
        {
          0: "选项4",
          1: "龙须面",
        },
        {
          0: "选项5",
          1: "北京烤鸭",
        },
      ],
      value1: "",
    };
  },
  created() {
    this.gettechnicallist();
    this.get();
  },
  methods: {
    async gettechnicallist() {
      const { data: res } = await this.$http.get(
        "bmtables/technical_features/0106",
        { params: this.queryinfo }
      );
      console.log(res);
      this.technicallist = res.data;
      this.total = res.top;
      // console.log(res.data.length);
    },
    handleSizeChange(newsize) {
      this.queryinfo.pagesize = newsize;
      this.gettechnicallist();

      // console.log(newsize);
      // console.log(this.queryinfo.pagesize);
      // console.log(this.current-page);
    },
    handleCurrentChange(newpage) {
      this.queryinfo.pagenum = newpage;
      this.gettechnicallist();
    },
    addDialogclosed() {
      this.$refs.addFormRef.resetFields();
    },
    add() {
      this.addForm.garlic_product_man_id = this.value1;
      this.$http({
        method: "post",
        url: "bmtabadd/0106/technical_features",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },

        data: this.addForm,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        console.log(res);
              this.gettechnicallist();
      this.$message(res.data);
      });
      console.log(typeof this.addForm.garlic_product_man_id);
      console.log(typeof this.addForm.seed_desc01);
      console.log(typeof this.value);
      console.log(this.addForm.garlic_product_man_id);
      console.log(this.addForm.seed_desc01);
      console.log(this.value);
      this.addDialogVisible = false;

    },
    async removebyid(id) {
      // id=scope.$index + 1
      console.log(id);
      const confirmResult = await this.$confirm(
        "此操作将永久删除该条信息, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // console.log(confirmResult)
      if (confirmResult != "confirm") {
        return this.$message.info("已取消删除");
      }
      // const { data: res } = await this.$http.post(
      //   "http://27.50.142.12:8000/bmtabdel",
      //   {
      //     tab: "dasuanbz_info",
      //     tid: tid,
      //   }
      // );
      // this.getdasuanlist();
      this.$http({
        method: "post",
        url: "bmtabdel",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        data: {
          tab: "technical_features",
          tid: id,
        },
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        // console.log(res.data);
              this.gettechnicallist();
      this.$message(res.data);
      });
      this.addDialogVisible = false;

    },
    async showEditDialog(id) {
      this.editdialogVisible = true;
      console.log(id);
      const { data: res } = await this.$http.get(
        "bmtabedit/0106/" + id + "/technical_features"
      );
      // console.log(res);
      this.editfrom = res;
      this.value1 = Number(this.editfrom.garlic_product_man_id[3]);

      // console.log(this.editfrom);
    },
    editDialogClosed() {
      this.$refs.editfromref.resetFields();
    },
    editfrominfo() {
      this.$http({
        method: "post",
        url: "bmedit/technical_features",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        data: {
          sid: "0106",
          tid: this.editfrom.id[1],
          seed_desc01: this.editfrom.seed_desc01[1],
          seed_desc01_select01: this.editfrom.seed_desc01_select01[1],
          seed_desc01_select02: this.editfrom.seed_desc01_select02[1],
          seed_desc01_select03: this.editfrom.seed_desc01_select03[1],
          seed_desc01_select04: this.editfrom.seed_desc01_select04[1],
          seed_desc01_select06: this.editfrom.seed_desc01_select06[1],
          seed_desc01_select05: this.editfrom.seed_desc01_select05[1],
          seed_desc02: this.editfrom.seed_desc02[1],
          seed_desc02_select01: this.editfrom.seed_desc02_select01[1],
          seed_desc02_select02: this.editfrom.seed_desc02_select02[1],
          seed_desc02_select03: this.editfrom.seed_desc02_select03[1],
          seed_desc02_select04: this.editfrom.seed_desc02_select04[1],
          seed_desc02_select06: this.editfrom.seed_desc02_select06[1],
          seed_desc02_select05: this.editfrom.seed_desc02_select05[1],
          seed_desc03: this.editfrom.seed_desc03[1],
          seed_desc03_select01: this.editfrom.seed_desc03_select01[1],
          seed_desc03_select02: this.editfrom.seed_desc03_select02[1],
          seed_desc03_select03: this.editfrom.seed_desc03_select03[1],
          seed_desc03_select04: this.editfrom.seed_desc03_select04[1],
          seed_desc03_select06: this.editfrom.seed_desc03_select06[1],
          seed_desc03_select05: this.editfrom.seed_desc03_select05[1],
          garlic_product_man_id: this.value1,
        },
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        // console.log(res.data);
              this.gettechnicallist();
      this.$message(res.data);
      });
      // console.log(this.editfrom.crop_name)
      this.editdialogVisible = false;

    },
    async get() {
      const { data: res } = await this.$http.get(
        "bmfkselect/garlic_product_man/technical_feature/0106"
      );
      console.log(res);
      this.options = res;
      console.log(this.options);
      console.log(this.op);
    },
      search() {
      this.queryinfo.pagenum = 1;
      this.gettechnicallist();
    },
  },
};
</script>

<style>
</style>