// import goodsApi from '@/api/shop/goods'
// import {getApiUrl} from '@/utils/utils'
// import permission from '@/directive/permission/index.js'
import edit from './edit.vue'

export default {
  name:'goods',
  // directives: { permission },
	 // 2. 注册组件
	    components: {
	        edit,
	    },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        name: undefined
      },
			shopOpen:false,
      total: 0,
      list: null,
      listLoading: true,
      selRow: {},
      // apiUrl: getApiUrl(),
	  editId:null,
	  userInfo:null
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  },
  created() {
  },
  mounted() {
	  // console.log("ddddddddd",sessionStorage.getItem('roleObj'));
	  this.userInfo = encodeURIComponent(sessionStorage.getItem('roleObj'));
	  this.init()
  },
  methods: {
    init() {
      this.fetchData()
    },
    fetchData() {
      this.listLoading = true
	  
	  this.$http({
	    method: "GET",
	    //url: "http://inside.cloudcyz.com:8000/mlogin",
	    url: "/admin-api/shop/goods/list?page="+this.listQuery.page+"&limit="+this.listQuery.limit+"",
	    headers: {
	      "Content-type": "application/x-www-form-urlencoded",
		  "userInfo": this.userInfo
	    },
	    // transformRequest: [
	    //   function (data) {
	    //     let ret = "";
	    //     for (let it in data) {
	    //       ret +=
	    //         encodeURIComponent(it) +
	    //         "=" +
	    //         encodeURIComponent(data[it]) +
	    //         "&";
	    //     }
	    //     return ret;
	    //   },
	    // ],
	  }).then(response => {
		  console.log("response===================",response);
        this.list = response.data.data.records
        this.listLoading = false
        this.total = parseInt(response.data.data.total) 
      })
      // goodsApi.getList(this.listQuery).then(response => {
      //   this.list = response.data.records
      //   this.listLoading = false
      //   this.total = response.data.total
      // })
    },
    search() {
      this.listQuery.page = 1
      this.fetchData()
    },
    reset() {
      this.listQuery.name = ''
      this.listQuery.page = 1
      this.fetchData()
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleClose() {

    },
    fetchNext() {
      this.listQuery.page = this.listQuery.page + 1
      this.fetchData()
    },
    fetchPrev() {
      this.listQuery.page = this.listQuery.page - 1
      this.fetchData()
    },
    fetchPage(page) {
      this.listQuery.page = page
      this.fetchData()
    },
    changeSize(limit) {
      this.listQuery.limit = limit
      this.fetchData()
    },
    handleCurrentChange(currentRow, oldCurrentRow) {
      this.selRow = currentRow
    },
    checkSel() {
      if (this.selRow && this.selRow.id) {
        return true
      }
      this.$message({
        message: this.$t('common.mustSelectOne'),
        type: 'warning'
      })
      return false
    },
    add() {
      // this.$router.push('goodsEdit')
	  this.editId = null
      this.shopOpen = true
    },
	cacelShop() {
	  // this.$router.push('goodsEdit')
	  this.shopOpen = false
	  this.fetchData()
	},
    edit(id) {
        // this.$router.push({path:'goodsEdit',query:{id:id}})
		console.log("dddddddddddddddd");
		this.editId = id
		this.shopOpen = true

    },
    changeIsOnSale(data){
		const formData = new FormData();
		formData.append('id', data.id); 
		formData.append('isOnSale', data.isOnSale);
		this.$http({
		  method: "post",
		  //url: "http://inside.cloudcyz.com:8000/mlogin",
		  url: "/shop/goods/changeIsOnSale",
		  data: formData,
		  headers: {
		    'Content-Type': 'multipart/form-data',
		  "userInfo": this.userInfo
		  },
		}).then(response => {
		  this.$message({
		    message: data.isOnSale?'上架成功':'下架成功',
		    type: 'success'
		  })
		})
		
		
      // goodsApi.changeIsOnSale(data.id,data.isOnSale).then( response =>{
      //   this.$message({
      //     message: data.isOnSale?'上架成功':'下架成功',
      //     type: 'success'
      //   })
      // })

    }

  }
}
