<template>
  <div id="app">
    <el-row>
      <el-col :span="24">
        <h4>H265播放器</h4>
        <div class="player-box">
          <div id="wasmPlayer"></div>
        </div>
        <div style="width:100%;margin: auto;">
          <div id="newplay" onClick="onplay"></div>
          <el-input v-model="input" placeholder="请输入播放地址接口" size="mini"></el-input>
          <el-button class="player-button" size="mini" type="success" @click="play">播放</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        input: 'http://124.160.101.172:7036/xx/1000104%240/substream/1.m3u8',      //视频播放地址(本地址已处理过)
        player: ''      //播放器对象
      }
    },
    mounted() {
      //实例化播放器
      this.player = new WasmPlayer(null, 'wasmPlayer', this.callbackfun, {
        Height: true, //Height:播放器尺寸是否继承父盒子的
        openAudio: true, //openAudio:是否打开音频
        BigPlay: false,
        HideKbs: true //HideKbs:是否隐藏实时码率
      })
      this.play()
    },
    methods: {
      // 播放事件
      play() {
        if (!this.input) {
          this.$message.error('请输入接口地址！')
        } else {
          this.player.play(this.input, 1)
        }
      },
      //回调函数
      callbackfun(e) {
        console.log(e)
        if (e == 'pause') {//暂停事件
          this.player.setSnap('images/logo_1.png')//显示封面图片
        } else if (e == 'play') {
          this.player.endSnap()//清除封面图片
          this.player.endLoading()//取消Loading显示
        }
      }
    }
  }
</script>
# 样式就不粘贴了， 官网都有