<template>
  <div class="login_container">
    <el-dialog
      title="注册"
      :visible.sync="zhuceDialogVisible"
      :modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
      @close="addDialogclosed"
    >
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="输入手机号" prefix-icon="el-icon-user" prop="user">
          <el-input v-model="ruleForm.user"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pwd1">
          <el-input
            type="password"
            v-model="ruleForm.pwd1"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="pwd2">
          <el-input
            type="password"
            v-model="ruleForm.pwd2"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
          <el-button @click="resetForm()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="login_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="../assets/2ef139de7f2e95fcd2dcd879eb9d3ce.png" alt="" />
      </div>
      <!-- 登录表单区域 -->
      <el-form
        :model="loginForm"
        :rules="loginFormRules"
        label-width="0px"
        class="login_form"
        ref="loginFormRef"
      >
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            prefix-icon="el-icon-user"
            
          ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            prefix-icon="el-icon-lock"
            type="password"
          ></el-input>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="primary" @click="zhuce">注册</el-button>

          <el-button type="info" @click="resetloginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var validatepwd1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.pwd2 !== "") {
          this.$refs.ruleForm.validateField("pwd2");
        }
        callback();
      }
    };
    var validatepwd2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pwd1) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var checkuser = (rule, value, cb) => {
      const reguser =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (reguser.test(value)) {
        return cb();
      }
      cb(new Error("请输入正确手机号"));
    };

    return {
      ruleForm: {
        user: "",
        pwd1: "",
        pwd2: "",
      },
      rules: {
        pwd1: [{ validator: validatepwd1, trigger: "blur" }],
        pwd2: [{ validator: validatepwd2, trigger: "blur" }],
        user: [
          { required: true, message: "输入手机号", trigger: "blur" },
          {
            validator: checkuser,
            trigger: "blur",
          },
        ],
      },
      zhuceDialogVisible: false,
      zhuceForm: {
        user: "",
        pwd1: "",
        pwd2: "",
      },
      // 登录表单的数据绑定对象
      loginForm: {
        username: "",
        password: "",
      },
      // 表单验证过规则对象
      loginFormRules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 点击重置按钮重置登录表单
    resetloginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    // 登录
    login() {
      this.$http({
        method: "post",
        url: "https://inside.cloudcyz.com/mlogin",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        data: this.loginForm,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        console.log(res.data.meta.stat);
        console.log(this.loginForm);
        if (res.data.meta.stat != true) return this.$message.error("登录失败 ");
        this.$message.success("登录成功");
        this.$router.push("./home");
      });
      // this.$refs.loginFormRef.validate(async (valid) => {
      //   if (!valid) return;
      //   const { data: res } = await this.$http.post(
      //     "http://127.0.0.1:3031/mlogin",
      //     this.loginForm
      //   );
      //   console.log(res);
      //   console.log(this.loginForm);
    },
    zhuce() {
      this.zhuceDialogVisible = true;
    },
    addDialogclosed() {
      this.$refs.zhuceFormRef.resetFields();
    },
    zhucefrominfo() {
      this.$http({
        method: "post",
        url: "mreg",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        data: this.zhuceForm,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        console.log(res.data);
        console.log(this.zhuceForm);
        if (res.data.meta.stat != true) return this.$message.error("登录失败 ");
        this.$message.success("登录成功");
        this.$router.push("./home");
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return;
        this.$http({
          method: "post",
          url: "mreg",
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
          data: this.ruleForm,
          transformRequest: [
            function (data) {
              let ret = "";
              for (let it in data) {
                ret +=
                  encodeURIComponent(it) +
                  "=" +
                  encodeURIComponent(data[it]) +
                  "&";
              }
              return ret;
            },
          ],
        }).then((res) => {
          console.log(res.data);
          this.$message(res.data.meta.msg);
        });
        // if(res.data.meta.status== -1){
        // this.$message("已注册");

        // }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login_container {
  background-color: #2b4b6b;
  height: 100%;
}
.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}
.btns {
  display: flex;
  justify-content: flex-end;
}
.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
</style>
