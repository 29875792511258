// import { remove, getList, save } from '@/api/shop/cart'

export default {
  name:'cart',
  data() {
    return {
      formVisible: false,
      formTitle: '添加购物车',
      isAdd: true,
      form: {
        idUser:'',
        idGoods:'',
        specifications:'',
        id: ''
      },
      listQuery: {
        page: 1,
        limit: 20,
        id: undefined
      },
      total: 0,
      list: null,
      listLoading: true,
      selRow: {}
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  },
  computed: {

    //表单验证
    rules() {
      return {
        // cfgName: [
        //   { required: true, message: this.$t('config.name') + this.$t('common.isRequired'), trigger: 'blur' },
        //   { min: 3, max: 2000, message: this.$t('config.name') + this.$t('config.lengthValidation'), trigger: 'blur' }
        // ]
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.fetchData()
    },
    fetchData() {
      this.listLoading = true
      // getList(this.listQuery).then(response => {
      //   this.list = response.data.records
      //   this.listLoading = false
      //   this.total = response.data.total
      // })
	  
	  this.$http({
	    method: "GET",
	    //url: "http://inside.cloudcyz.com:8000/mlogin",
	    url: "/admin-api/shop/cart/list?page="+this.listQuery.page+"&limit="+this.listQuery.limit+"",
	    headers: {
	      "Content-type": "application/x-www-form-urlencoded",
	  		  "userInfo": this.userInfo
	    },
	  }).then(response => {
	  		  console.log("response===================",response);
	    this.list = response.data.data.records
	    this.listLoading = false
	    this.total = parseInt(response.data.data.total) 
	  })
	  
	  
    },
    search() {
      this.listQuery.page = 1
      this.fetchData()
    },
    reset() {
      this.listQuery.id = ''
      this.listQuery.page = 1
      this.fetchData()
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleClose() {

    },
    fetchNext() {
      this.listQuery.page = this.listQuery.page + 1
      this.fetchData()
    },
    fetchPrev() {
      this.listQuery.page = this.listQuery.page - 1
      this.fetchData()
    },
    fetchPage(page) {
      this.listQuery.page = page
      this.fetchData()
    },
    changeSize(limit) {
      this.listQuery.limit = limit
      this.fetchData()
    },
    handleCurrentChange(currentRow, oldCurrentRow) {
      this.selRow = currentRow
    },
    formatPrice(price) {
		console.log(price);
      return '¥' + (price / 100).toFixed(2)
    },

  }
}
