// import orderApi from '@/api/shop/order'
// import expressApi from '@/api/system/express'
// import { getApiUrl } from '@/utils/utils'
export default {
	name: 'order',
	data() {
		return {
			addBackbugDialog: false,
			addBackbugDialogParams: {
				orderSn: "",
				backbugRate: ""
			},
			listQuery: {
				page: 1,
				limit: 20,
				mobile: undefined,
				orderSn: undefined,
				status: undefined,
				date: undefined,
				startDate: undefined,
				endDate: undefined
			},
			applyStatusStr: ["", "待审核", "审核通过", "驳回"],
			orderDate: undefined,
			total: 0,
			list: null,
			listLoading: true,
			selRow: {},
			logVisible: false,
			logs: [],
			expressList: [],
			//发货表单
			shipping: {
				show: false,
				id: '',
				idExpress: '',
				shippingSn: ''
			},
			//物流信息
			shippingInfo: {
				show: false,
				form: {
					id: '',
					idExpress: '',
					shippingSn: '',
					traces: []
				}
			},
			query: {
				button: {
					lastStatus: 'all',
					lastDate: 'all',
					showCustomer: false,
					css: {
						status: {
							all: 'primary',
							unPay: 'default',
							unSend: 'default',
							sended: 'default',
							finished: 'default',
							cancel: 'default',
							refundIng: 'default',
							refund: 'default'
						},
						date: {
							all: 'primary',
							today: 'default',
							yesterday: 'default',
							seven: 'default',
							thirty: 'default',
							month: 'default',
							year: 'default',
							customer: 'default'
						}
					},
					tag: {
						unPay: 0,
						unSend: 0,
						sended: 0,
						finished: 0,
						cancel: 0,
						refundIng: 0,
						refund: 0
					}
				}
			}
		}
	},
	filters: {
		statusFilter(status) {
			const statusMap = {
				published: 'success',
				draft: 'gray',
				deleted: 'danger'
			}
			return statusMap[status]
		}
	},
	created() {
		this.init()
	},
	methods: {
		paid(orderSn, status) {
			let _this = this
			orderApi.updateStatus(orderSn, status).then(res => {
				_this.$message({
					message: "设置成功",
					type: 'success'
				})
				_this.init()
			})
		},
		addBackbug(orderSn) {
			if (orderSn) {
				this.addBackbugDialogParams.orderSn = orderSn
			} else {
				if (this.addBackbugDialogParams.backbugRate && this.addBackbugDialogParams.orderSn) {
					let _this = this

					this.$http({
						method: "POST",
						url: "/admin-api/shop/order/updateRate?orderNo=" + _this.addBackbugDialogParams
							.orderSn + "&repurchasing=" + _this.addBackbugDialogParams.backbugRate,
						headers: {
							"Content-type": "application/x-www-form-urlencoded",
							"userInfo": _this.userInfo
						}
					}).then(response => {
						console.log("response===================", response);
						if (response.data.code == 2000) {
							_this.$message({
								message: "设置回购率成功",
								type: 'success'
							})
						} else {
							_this.$message({
								message: "设置回购率失败",
								type: 'error'
							})
						}

						_this.init()
					})

				} else {
					this.$message({
						message: "参数异常",
						type: 'warning'
					})
				}
			}
			this.addBackbugDialog = !this.addBackbugDialog;
		},
		opcApplyStatus(row, status) {
			if (status && status <= 3 && status > 1) {
				let {
					orderSn
				} = row
				let _this = this
				console.log(orderSn, status)
				orderApi.updateApplyStatus(orderSn, status).then(() => {
					_this.init()
					_this.$message({
						message: _this.applyStatusStr[status],
						type: 'success'
					})
				})
			} else {
				this.$message({
					message: "状态为空",
					type: 'warning'
				})
			}
		},
		init() {
			let _this = this;
			this.fetchData()

			this.$http({
				method: "GET",
				url: "/admin-api/sys/express/queryAll",
				headers: {
					"Content-type": "application/x-www-form-urlencoded",
					"userInfo": _this.userInfo
				}
			}).then(response => {
				console.log("response===================", response);
				this.expressList = response.data.data
			})

		},
		fetchData() {
			let _this = this;
			_this.listLoading = true
			if (_this.orderDate) {
				_this.listQuery['startDate'] = _this.orderDate[0]
				_this.listQuery['endDate'] = _this.orderDate[1]
			}

			this.$http({
				method: "GET",
				url: "/admin-api/shop/order/list?page=" + _this.listQuery.page + "&limit=" + _this.listQuery
					.limit + "",
				headers: {
					"Content-type": "application/x-www-form-urlencoded",
					"userInfo": _this.userInfo
				},
				params: _this.listQuery
			}).then(response => {
				console.log("response===================", response);
				const data = response.data
				_this.list = data.data.records
				_this.listLoading = false
				_this.total = data.total

				for (const key in data.OrderStatistic) {
					_this.query.button.tag[key] = data.OrderStatistic[key]
				}
			})
		},
		search() {
			this.listQuery.page = 1
			this.fetchData()
		},
		reset() {
			this.listQuery.mobile = ''
			this.listQuery.orderSn = ''
			this.listQuery.page = 1
			this.fetchData()
		},
		queryByState(status) {
			const lastStatus = this.query.button.lastStatus
			this.query.button.css.status[lastStatus] = 'default'
			this.query.button.css.status[status] = 'primary'
			this.query.button.lastStatus = status
			if (status !== 'all') {
				this.listQuery.status = status
			} else {
				this.listQuery.status = ''
			}
			this.fetchData()
		},
		queryByDate(date) {
			const lastDate = this.query.button.lastDate
			this.query.button.css.date[lastDate] = 'default'
			this.query.button.css.date[date] = 'primary'
			this.query.button.lastDate = date
			if (date !== 'all' && date !== 'customer') {
				this.listQuery.date = date
			} else {
				this.listQuery.date = ''
			}
			if (date === 'customer') {
				this.query.button.showCustomer = true
				return
			} else {
				this.query.button.showCustomer = false
			}
			this.fetchData()
		},
		handleFilter() {
			this.listQuery.page = 1
			this.getList()
		},
		handleClose() {

		},
		fetchNext() {
			this.listQuery.page = this.listQuery.page + 1
			this.fetchData()
		},
		fetchPrev() {
			this.listQuery.page = this.listQuery.page - 1
			this.fetchData()
		},
		fetchPage(page) {
			this.listQuery.page = page
			this.fetchData()
		},
		changeSize(limit) {
			this.listQuery.limit = limit
			this.fetchData()
		},
		handleCurrentChange(currentRow, oldCurrentRow) {
			this.selRow = currentRow
		},
		checkSel() {
			if (this.selRow && this.selRow.id) {
				return true
			}
			this.$message({
				message: '请选中操作项!',
				type: 'warning'
			})
			return false
		},
		formatPrice(price) {
			return (price / 100).toFixed(2);
		},
		viewShippingInfo(data) {
			this.shippingInfo.form = data
			const expressList = this.expressList
			let shipperCode = ''
			for (const index in expressList) {
				const express = expressList[index]
				if (express.id === data.idExpress) {
					shipperCode = express.code
				}
			}
			orderApi.getExpressInfo(data.orderSn, shipperCode).then(response => {
				this.shippingInfo.form['traces'] = response.data.infoList
				this.shippingInfo.show = true
			})

		},
		openSendOutForm(id) {
			this.shipping.id = id
			this.shipping.show = true
		},
		sendOut() {
			this.$confirm('确认该操作？', '提示', {
				confirmButtonText: '提交',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				orderApi.sendOut(this.shipping.id, this.shipping.idExpress, this.shipping.shippingSn).then(
					response => {
						this.fetchData()
						this.shipping.show = false
						this.$message({
							message: '发货成功',
							type: 'success'
						})
					})
			}).catch(() => {})
		},
		viewLog(id) {
			let _this = this;
			this.logs = []
			this.logVisible = true
			this.$http({
				method: "GET",
				url: "/admin-api/shop/order/log/queryByIdOrder?orderId=" + id,
				headers: {
					"Content-type": "application/x-www-form-urlencoded",
					"userInfo": _this.userInfo
				}
			}).then(response => {
				console.log("response===================", response);
				this.logs = response.data.data
			})
		},
		addComment(id) {
			let _this = this;
			this.$prompt('请输入备注信息', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(({
				value
			}) => {
				_this.$http({
					method: "POST",
					url: "/admin-api/shop/order/comment?orderId=" + id + "&message=" + value,
					headers: {
						"Content-type": "application/x-www-form-urlencoded",
						"userInfo": _this.userInfo
					},
				}).then(response => {
					console.log("response===================", response);
					// this.expressList = response.data.data
				})
			}).catch(() => {
				_this.$message({
					type: 'info',
					message: '取消输入'
				});
			});
		},
		refund(orderSn) {
			const _this = this
			orderApi.refund(orderSn).then(res => {
				_this.$message({
					message: '退款成功，等待微信返回结果',
					type: 'success'
				})
			}).catch(() => {
				_this.$message({
					message: '退款成功，等待微信返回结果',
					type: 'warn'
				})
			})
		},
		exportXls() {
			let _this = this;
			this.$http({
				method: "GET",
				url: "/shop/order/export",
				headers: {
					"Content-type": "application/x-www-form-urlencoded",
					"userInfo": _this.userInfo
				},
				params: _this.listQuery
			}).then(response => {
				// console.log("response===================", response);
				// this.expressList = response.data.data
				window.location.href = ApiUrl_My + '/file/download?fileName=' + response.data.data
					.realFileName
			})
		}
	}
}