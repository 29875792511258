<template>
  <div class="databody" >
    <!-- 左边 -->
    <div class="dataLeft">
      <!-- 实时天气和温度 -->
      <div class="dataBox_c" id="realtime">
        <div class="dataTit_c">实时天气和温度</div>
        <ul v-if="yc_show_realtime[0]">
          <li class="List_3">
            <div><i class="iconfont icon-wendu"></i>温度</div>
            <div class="M_cyan">{{yc_show_realtime[0].iot_value||''}}</div>
          </li>
          <li class="List_3">
            <div><i class="iconfont icon-w_shidu"></i>空气湿度</div>
            <div class="M_cyan">{{yc_show_realtime[1].iot_value||''}}</div>
          </li>
          <li class="List_3">
            <div><i class="iconfont icon-guangzhao"></i>光照度</div>
            <div class="M_cyan">{{yc_show_realtime[2].iot_value||''}}</div>
          </li>
        
          <div>--------------------------------------------------------------</div>

          <li class="List_3">
            <div><i class="iconfont icon-wendu"></i>土壤温度</div>
            <div class="M_cyan">{{yc_show_realtime[4].iot_value||''}}</div>
          </li>
          <li class="List_3">
            <div><i class="iconfont icon-w_shidu"></i>土壤湿度</div>
            <div class="M_cyan">{{yc_show_realtime[5].iot_value||''}}</div>
          </li>
          <li class="List_3">
            <div><i class="iconfont icon-turangdiandaoshuai"></i>土壤电导率</div>
            <div class="M_cyan">{{yc_show_realtime[6].iot_value||''}}</div>
          </li>
            <div>--------------------------------------------------------------</div>
          <li class="List_3">
            <div><i class="iconfont icon-jiangyuliang"></i>雨量</div>
            <div class="M_cyan">{{yc_show_realtime[3].iot_value||''}}</div>
          </li>
        </ul>
      </div>
      <!-- 土地基本信息 -->
      <div class="dataBox_c" id="basic">
        <div class="dataTit_c">土地基本信息</div>
        <ul v-if="yc_show_xinxi[0]">
          <li class="List_2">
            <div class="borderBox">
              <span class="cont topleft"></span>
              <span class="cont topright"></span>
              <span class="cont bottomleft"></span>
              <span class="cont bottomright"></span>
              <i class="iconfont icon-mianji"></i>
            </div>
            <div class="wordBox">
              <div class="M_cyan">{{yc_show_xinxi[0]}}</div>
              <div>园区面积</div>
            </div>
          </li>
          <li class="List_2">
            <div class="borderBox">
              <span class="cont topleft"></span>
              <span class="cont topright"></span>
              <span class="cont bottomleft"></span>
              <span class="cont bottomright"></span>
              <i class="iconfont icon-dasuan"></i>
            </div>
            <div class="wordBox">
              <div class="M_cyan">{{yc_show_xinxi[1]}}</div>
              <div>大蒜</div>
            </div>
          </li>
          <li class="List_2">
            <div class="borderBox">
              <span class="cont topleft"></span>
              <span class="cont topright"></span>
              <span class="cont bottomleft"></span>
              <span class="cont bottomright"></span>
              <i class="iconfont icon-dapengzhongmiaoguanli"></i>
            </div>
            <div class="wordBox">
              <div class="M_cyan">{{yc_show_xinxi[2]}}</div>
              <div>育苗大棚</div>
            </div>
          </li>
          <li class="List_2">
            <div class="borderBox">
              <span class="cont topleft"></span>
              <span class="cont topright"></span>
              <span class="cont bottomleft"></span>
              <span class="cont bottomright"></span>
              <i class="iconfont icon-zhongzhidangan"></i>
            </div>
            <div class="wordBox">
              <div class="M_cyan">{{yc_show_xinxi[3]}}</div>
              <div>种植大棚</div>
            </div>
          </li>
          <li class="List_2">
            <div class="borderBox">
              <span class="cont topleft"></span>
              <span class="cont topright"></span>
              <span class="cont bottomleft"></span>
              <span class="cont bottomright"></span>
              <i class="iconfont icon-shuidao"></i>
            </div>
            <div class="wordBox">
              <div class="M_cyan">{{yc_show_xinxi[4]}}</div>
              <div>水稻</div>
            </div>
          </li>
          <li class="List_2">
            <div class="wenzi" >
              <span class="cont topleft"></span>
              <span class="cont topright"></span>
              <span class="cont bottomleft"></span>
              <span class="cont bottomright"></span>
              <!-- <i class="iconfont icon-mianji"></i> -->
              
              <span>{{yc_show_xinxi[5]}} </span>
            </div>
            
          </li>
        </ul>
      </div>
      <!-- 专家咨询 -->
      <div class="dataBox_c" id="expert">
        <div class="dataTit_c">专家咨询</div>
        <ul>
          <li class="List_1" v-for="(item,index) in yc_show_content" :key="index">
            <div class="M_cyan">
              {{item}}?
            </div>
          </li>
        
        </ul>
      </div>
    </div>
    <!-- 中间 -->
    <div class="dataCenter">
      <!-- 头部标题 -->
      <div class="haed">
        <div class="headWeather haedTransform">
          <span id="Weather">
            <i class="iconfont icon-tianqi">什邡市</i>
            <div class="weather">{{temp1}}{{temp2}}</div>
          </span>
        </div>
        <div class="headDate haedTransform">
          <span>
            <!-- <div id="date" style="font-size: 14px;">2022/1/14</div> -->
            <div id="time">{{ dateshishi }}</div>
          </span>
        </div>
        <div class="headTit">云锄智慧农业物联网平台</div>
      </div>
      <!-- 公告 -->
      <div id="notice" class="notice">
        <div class="noticeBtn">公告</div>
        <!-- <div class="noticeWord">
          国内棉价后期价格持续大涨可能性不大，建议适时有序
        </div> -->
  
      <!-- <div style="height:48px;overflow: hidden;">
          <div class="noticeWord"  v-for="(item, index) in ulList"
        :key="item.id"
        :class="!index && play ? 'toUp' : ''">
          {{ item.msg }}
        </div>
      </div> -->
      <div id="demo" class="noticeWord">
    <ul class="list" >
      <li
      style="text-align: left;
  height: 40px;"
        v-for="(item, index) in ulList"
        :key="item.id"
        :class="!index && play ? 'toUp' : ''"
      >
        {{ item }}
      </li>
    </ul>
  </div>
      </div>
      <!-- 预警 -->
      <div id="earlyWarn" class="earlyWarn">
        <div class="earlyWarnBtn">预警</div>
        <!-- <div class="earlyWarnWord">近期雨水增多，注意排水</div> -->
            <div id="demo" class="earlyWarnWord">
    <ul class="list" >
      <li
      style="text-align:left;dispaly:inline-block;
  height: 40px;"
        v-for="(item, index) in warning"
        :key="item.id"
        class="liblock"
        :class="!index && play1 ? 'toUp1' : ''"
      >
        {{ item }}
      </li>
    </ul>
  </div>
      </div>
      <!-- 视频 -->
 <div class="ycPlayer">
        <div id="muiPlayer">
            
        </div>
        <div class="playerBorL">
<video
      id="my-video"
      ref='videosrc'
      class="video-js vjs-default-skin"
      controls
   style="width:100%;height:100%"
      preload="auto"
      :poster="imgsrc"
      >
      <source :src="srcUrl.default" />
    </video>
    <!-- {{srcUrl.default}} -->
        </div>
        
        <div class="playerMsg" id="playerMsg">
          <span style="font-size:18px">1号地</span>
          <div style="margin-top:15px">种植作物:大蒜</div>
          <div style="margin-top:15px">种植数量:5亩</div>
          <div style="margin-top:15px">植物状态:优秀</div>
        </div>

      </div>

<!-- <div>
    <video
      id="my-video"
      class="video-js vjs-default-skin"
      controls
      preload="auto"
      width="500px">
      <source :src="srcUrl" />
    </video>
  </div> -->

      <!-- 数据监测 -->
      <div class="DataMonitor">
        <span class="cont topleft"></span>
        <span class="cont topright"></span>
        <span class="cont bottomleft"></span>
        <span class="cont bottomright"></span>
        <ul class="listBox" v-if="yc_show_status[0]">
          <li style="width:25%">
            <div class="borderBox">
              <span class="cont topleft"></span>
              <span class="cont topright"></span>
              <span class="cont bottomleft"></span>
              <span class="cont bottomright"></span>
              <i class="iconfont icon-wenduchuanganqi"></i>
            </div>
            <div class="title">气象传感器</div>
            <div class="type">{{yc_show_status[0].air_run_stu}}</div>
          </li>
          <li style="width:25%">
            <div class="borderBox">
              <span class="cont topleft"></span>
              <span class="cont topright"></span>
              <span class="cont bottomleft"></span>
              <span class="cont bottomright"></span>
              <i class="iconfont icon-turang_"></i>
            </div>
            <div class="title">土壤传感器</div>
            <div class="type">{{yc_show_status[1].soil_run_stu}}</div>
          </li>
          <li style="width:25%">
            <div class="borderBox">
              <span class="cont topleft"></span>
              <span class="cont topright"></span>
              <span class="cont bottomleft"></span>
              <span class="cont bottomright"></span>
              <i class="iconfont icon-a-28atongfengfengshan"></i>
            </div>
            <div class="title">风向检测</div>
            <div class="type">{{yc_show_status[2].fen_run_stu}}</div>
          </li>
          <li style="width:25%">
            <div class="borderBox">
              <span class="cont topleft"></span>
              <span class="cont topright"></span>
              <span class="cont bottomleft"></span>
              <span class="cont bottomright"></span>
              <i class="iconfont icon-zu"></i>
            </div>
            <div class="title">传感器</div>
            <div class="type">{{yc_show_status[3].sensor_status_run[0]}}  <span>{{yc_show_status[3].sensor_status_run[1]}}</span></div>
            <div class="type">{{yc_show_status[3].sensor_status[0]}}  <span>{{yc_show_status[3].sensor_status[1]}}</span></div>
            <div class="type">{{yc_show_status[3].sensor_online[0]}}  <span>{{yc_show_status[3].sensor_online[1]}}</span></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 右边 -->
    <div class="dataRight">
      <!-- 基地分布 -->
      <div class="dataBox_c" id="base">
        <div class="dataTit_c">基地分布</div>
        <div id="map" class="dataRmap" ref="map" @dblclick="revertmap" ></div>
        <!-- <div id="map" class="dataRmap"  @dblclick="revertmap" ></div> -->
      </div>
      <!-- 种植技术 -->
      <div class="dataBox_c" id="plant" style="margin-top: 20px">
        <div class="dataTit_c">种植数据</div>
        <div class="plantData">
          <ul v-if="yc_show_zhongzhidata.tle_1">
            <li>当前总面积：<span class="data">{{yc_show_zhongzhidata.tle_1
[1]}}亩</span></li>
            <li>预产总产量：<span class="data">{{yc_show_zhongzhidata.tle_2
[1]}}斤</span></li>
            <li>预计总产值：<span class="data">{{yc_show_zhongzhidata.tle_3
[1]}}元</span></li>
            <li>总地块数：<span class="data">{{yc_show_zhongzhidata.tle_4
[1]}}</span></li>
            <li>合作农户：<span class="data">{{yc_show_zhongzhidata.tle_5
[1]}}</span></li>
            <li>
              作物类型：<span class="data">{{yc_show_zhongzhidata.tle_6
[1]}}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 作物分布 -->
      <div class="dataBox_c" id="crop" style="margin-top: 10px" >
        <div class="dataTit_c">作物分布</div>
        <div id="pie" class="cropChart" ref="pie" style="width:400px;hieght:500px"></div>
        
      </div>
    </div>

  </div>
</template>

<script>
import {getProvinceMapInfo} from '@/utils/map_utils.js'
import 'videojs-contrib-hls'
import videojs from "video.js";
export default {
  props:{
    
  },
  name: "VideoPlayer",
    mounted(){
      setInterval(this.startPlay, 2000)
      setInterval(this.startPlay1, 2000)
        // map.centerAndZoom(point, 15);  
// console.log(this.$refs.map);
this.getweatherinfo()
this.getyc_show_realtime()
this.getyc_show()
this.getyc_show_status()
this.initchart()
this.updatecharts()
this.initmap()
// this.getVideo();
    },
data(){
    return{
      imgsrc:'',
        srcUrl:{
        type:String,
        // default:'http://112.194.204.114:7086/live/cameraid/1000001%240/substream/2.m3u81.m3u8'
        default:''
      },
      ulList: [
      ],
      warning:[],
      play: false,
      play1: false,
date:'',
temp1:'',
temp2:'',
yc_show_realtime:[],
yc_show:[],
yc_show_xinxi:[],
yc_show_content:[],
yc_show_zhongzhidata:[],
yc_show_status:[],
piechart:null,
mapchart:null,
piedata:[
  // {name:'大蒜',value:12},
  // {name:'水稻',value:35},
  // {name:'食用菌',value:23},
  // {name:'桃子',value:30}
],

    }
    
},
methods:{
   startPlay() {
      let that = this
      that.play = true //开始播放
      setTimeout(() => {
        that.ulList.push(that.ulList[0]) //将第一条数据塞到最后一个
        that.ulList.shift() //删除第一条数据
        that.play = false //暂停播放,此处修改，保证每一次都会有动画显示
      }, 500)
    },
   startPlay1() {
      let that = this
      that.play1 = true //开始播放
      setTimeout(() => {
        that.warning.push(that.warning[0]) //将第一条数据塞到最后一个
        that.warning.shift() //删除第一条数据
        that.play1 = false //暂停播放,此处修改，保证每一次都会有动画显示
      }, 1500)
    },
async getweatherinfo(){
    // this.axios.get('http://www.weather.com.cn/data/cityinfo/101272004.html')
      const {data:res} = await this.$http({
        method:'get',
        url:'api/data/cityinfo/101272004.html'
    })
    this.temp1 = res.weatherinfo.temp1
    this.temp2 = res.weatherinfo.temp2
//  setInterval(async ()=>{
//   const {data:res} = await this.$http({
//         method:'get',
//         url:'api/data/cityinfo/101272004.html'
//     })
//     this.temp1 = res.weatherinfo.temp1
//     this.temp2 = res.weatherinfo.temp2
//  },20000)

},
async getyc_show_realtime(){
const {data:res} = await this.$http({
  method:"post",
  url:'/yc_show_realtime',
  params:{
    yc_show_real:'yc_show_real'
  }
})
this.yc_show_realtime = res.data
setInterval(async()=>{
  const {data:res} = await this.$http({
  method:"post",
  url:'/yc_show_realtime',
  params:{
    yc_show_real:'yc_show_real'
  }
})
this.yc_show_realtime = res.data
},15000)
},   
async getyc_show_status(){
const {data:res} = await this.$http({
  method:"post",
  url:'//yc_show_status',
  params:{
    yc_show:'show_status'
  }
})
// this.yc_show_realtime = res.data
this.yc_show_status = res
},   
async getyc_show(){
  let params = {
        jump_sid:'0521'
      };
      this.$http({
        method: "post",
        url: "/yc_show",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },

        data: params,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res)=>{
        this.yc_show = res.data.datas       
        this.yc_show_xinxi = Object.values(this.yc_show[3].title_2[0])
        this.yc_show_content = this.yc_show[4].content
        this.yc_show_zhongzhidata = this.yc_show[6]
        console.log(res.data);
        this.piedata = this.yc_show[7].title_zw
        this.ulList = this.yc_show[0].notice
        this.warning = this.yc_show[1].warning

        this.srcUrl.default = res.data.viode
        this.imgsrc = res.data.img
        setTimeout(() => {
          this.getVideo();
        }, 100);
 
      
        // this.$refs.videosrc.src = res.data.viode
        // this.$refs.videosrc.play()
//         let player = document.querySelector('my-video') 
// player.src = res.data.viode 
// player.play()
// this.getVideo()
this.updatecharts()
      })
      
},
async initchart(){
 this.piechart =  this.$echarts.init(document.getElementById('pie'))

},
updatecharts(){
  const option = {
series:[
  {
    type:'pie',
    data:this.piedata,
    label:{
fontSize:20
    }
  }
]
  }
  this.piechart.setOption(option)
},
async initmap(){
 this.mapchart =  this.$echarts.init(this.$refs.map)
const res = await this.$http({
  method:'get',
  // url:'http://localhost:8080/map/china.json'
  url:'https://ycbm.cloudcyz.com/map/china.json'
  // url:'@/map/china.json'
})
console.log(res)
this.$echarts.registerMap('china',res.data)
const initOption = {
  geo:{
    type:'map',
    map:'china',
    top:'5%',
    bottom:'5%',
    itemStyle:{
      areaColor:'#2172BF'
    }
  },
   series:[{
     type:'effectScatter',
  name:'分布情况',
  data:[
    {
      value:[104.048,31.2127]
    },
    {
      value:[104.058,31.2227]
    }
  ],
  coordinateSystem:'geo'
  }]
}
this.mapchart.setOption(initOption)
this.mapchart.on('click',async arg=>{
 const nameinfo = getProvinceMapInfo(arg.name)
 console.log(nameinfo);
 const ret = await this.$http({
   method:'get',
  // url:`http://localhost:8080${nameinfo.path}`
  url:`https://ycbm.cloudcyz.com${nameinfo.path}`
 })
 console.log(ret);
this.$echarts.registerMap(nameinfo.key,ret.data)
const changeOption = {
  geo:{
    map:nameinfo.key
  }
}
this.mapchart.setOption(changeOption)

})
},
revertmap(){
  const revertOption = {
    geo:{
      map:'china'
    }
  }
this.mapchart.setOption(revertOption)

},
getVideo() {
        videojs(
            "my-video",
            {
                bigPlayButton: true,
                textTrackDisplay: false,
                posterImage: true,
                errorDisplay: false,
                controlBar: false
            },
            function() {
                this.play();
            }
        );
},},
computed:{
dateshishi(){
  this.date = new Date().toLocaleString()
    setInterval(() => {
    this.date = new Date().toLocaleString()
        
    }, 1000);
    return this.date
},
}
}
</script>

<style scoped>
video{
   width: 100%;
   height: 100%;
   object-fit: fill;
}
video:focus {
  outline:none;
}
.liblock{
  display: inline-block;
  width: 100%;
}
.databody {
  background: url("../../assets/img/bg.png") no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}
.wenzi{
  display: inline-block;
}
.toUp {
  margin-top: -40px;
  transition: all 0.5s;
}
.toUp1{
  margin-top: -40px;
  transition: all 0.5s;
}

.list {
  list-style: none;
  /* width: 200px; */
  text-align: center;
  overflow: hidden;
  height: 40px;
  padding: 0;
  margin: 0;
}
body,html {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
ul{
    list-style: none;
}
.layui-btn{
    background: #45ad70;
}
/*==================================左侧菜单===================================*/
.layui-layout-admin .layui-logo {
    background-color: #fff;
    z-index: 1111;
    color: #333;
    border-right: #ccc solid 1px;
}

.layui-header {
    background-color: #fff;
    border-bottom: #ccc solid 1px;
    z-index: 9999;
}

.layui-layout-admin .layui-side {
    z-index: 2222;
}

.layui-nav {
    background: #fff;
    border-right: none;
}

.layui-side-scroll {
    background: #fff;
}

#topTit a {
    color: #333;
}
#topTit .layui-nav-child a{
    color: #333;
}

.layui-nav-child .true {
    height: 50px;
    /*background: #F59A23;*/
    background: #0037fa;
}

.layui-nav-child .true i {
    color: #fff;
}

.layui-nav-tree .layui-nav-child a i {
    margin-left: 20px;
}


.layui-nav-tree .layui-nav-item a {
    height: 55px;
    line-height: 55px;
    color: #666;
}

.layui-nav-tree .layui-nav-child a {
    height: 50px;
    line-height: 50px;
}

.menu_icon {
    margin-right: 10px;
    color: #737781;
}


.layui-layout-admin .layui-logo {
    width: 210px;
}

.layui-layout-admin .layui-side {
    width: 210px;
}

.layui-side-scroll {
    width: 230px;
}

.layui-nav-tree {
    width: 210px;
}

.layui-body {
    left: 210px;
    top:60px;
}

/**==================================菜单===================================*/
.layui-nav .layui-nav-item a{
    color: #333;
}
.layui-nav .layui-nav-item a:hover, .layui-nav .layui-this a{
    color: #4aaf73;
}
.meunLogo{
    font-size: 20px;
}
.meunLogo img{
    height: 40px;
    width: auto;
    margin-right: 20px;
}
.menuList a{
    font-size: 16px;
    background-image:-webkit-linear-gradient(top, #666, #333);
    background-position: center center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.menuList .iconfont{
    font-size: 18px;
    margin-right: 10px;
}
.chat a{
    color: #4aaf73;
    background-image:-webkit-linear-gradient(top, #4aaf73, #1b70ad);
}

/*==================================聊天===================================*/
.chatBody{
    overflow: hidden;
    margin-top: 10px;
    height: calc(100% - 70px);
}
.chatList{
    float: left;
    width: 400px;
    height: 100%;
    background: #fff;
    margin-right: 10px;
    overflow: auto;
}
.chatList li{
    padding: 15px 10px 15px 10px;
    border-bottom: #ccc solid 1px;
}
.chatList .lichouse{
    background: #eef0f2;
}
.chatList li .chatUserImg{
    display: inline-block;
    vertical-align: top;
    width: 40px;
    height: 40px;
    margin-top: 8px;
    border-radius: 50%;
    overflow: hidden;
    background: #ccc;
}
.chatList li .chatUserMsg{
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 55px);
    margin-left: 10px;
}
.chatList li .userName{
    float: left;
    font-size: 16px;
    font-weight: bold;
    /*line-height: 30px;*/
    /*height: 30px;*/
}
.chatList li .userDate{
    float: right;
    color: #999;
    /*line-height: 30px;*/
    /*height: 30px;*/
}

.chatList li .userWord{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 10px;
}


.chatWinBox{
    float: left;
    width: calc(100% - 430px);
    height: 100%;
    background: #fff;
}
.chatWinHead{
    height: 80px;
    border-bottom: #ccc solid 1px;
}
.chatWinHead .img{
    float: left;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 10px;
    background: #ccc;
    margin-left: 30px;
}
.chatWinHead .tname{
    float: left;
    line-height: 80px;
    font-size: 18px;
    font-weight: bold;
    margin-left: 15px;
}
.chatWinHead .btn{
    float: right;
    margin-top: 21px;
    margin-right: 30px;
}

.chatWinBody{
    height: calc(100% - 305px);
    overflow: auto;
}
.chatWinSend{
    height: 220px;
    border-top: #ccc solid 1px;
}

.M_chat_left,.M_chat_right{
    overflow: hidden;
    margin: 20px 10px 0px 10px;
}
.M_chatHead{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    background: #ccc;
}
.M_chat_left .M_chatHead{
    margin: 0 0 0 5px;
    float: left;
}
.M_chat_right .M_chatHead{
    margin: 0 5px 0 0;
    float: right;
}

.M_chatBox{
    width: auto;
    max-width: 60%;
    position: relative;
}
.M_chat_time{
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
}
.M_chat_left .M_chatBox{
    margin: 10px 0 0 20px;
    float: left;
}
.M_chat_right .M_chatBox{
    margin: 10px 20px 0 0;
    float: right;
}

.M_chatBox .triangle{
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
    content: ' ';
    background: #aefff1;
    position: absolute;
    left: -5px;
    top: 10px;
}
.M_chat_right .M_chatBox .triangle{
    right: -5px;
    left: auto;
    background: #ffd88b;
}

.M_chatBox .M_chatWord{
    padding: 10px;
    background: #aefff1;
    border-radius: 12px;
}

.M_chat_right .M_chatWord{
    background: #ffd88b;
}

.chatWinSend .file{
    padding: 0px 20px 0px 0px;
    line-height: 50px;
    text-align: right;
}
.chatWinSend .file .iconfont{
    font-size: 22px;
}
.chatWinSend .msg{
    padding: 0px 30px 0px 30px;
    height: calc(100% - 100px);
}
.chatWinSend .msg textarea{
    width: 100%;
    height: 100%;
    border: none;
}
.chatWinSend .btn{
    height: 50px;
    overflow: hidden;
}
.chatWinSend .btnLeft{
    float: left;
    margin-left: 30px;
}
.chatWinSend .btnRight{
    float: right;
    margin-right: 30px;
}

/*==================================大屏===================================*/
.databody{
    width: 100%;
    height: 100%;
    min-width: 1980px;
    color: #fff;
    overflow: auto;
}
.dataLeft{
    float: left;
    width: 23%;
    margin-top: 30px;
}
.dataCenter{
    float: left;
    width: 54%;
    height: 100%;
    border-left:2px solid;
    border-right:2px solid;
    border-image: linear-gradient(#010f33,#2496b9,#010f33)30 30;
}
.dataRight{
    float: left;
    width: 22%;
    margin-top: 30px;
}
.dataBox_c{
    padding: 10px 20px 10px 20px;
}
.dataTit_c{
    background-image: linear-gradient(to right, #015dd3 , #031039);
    height: 50px;
    line-height: 50px;
    padding-left: 10px;
    font-size: 22px;
    font-weight: bold;
}
.M_cyan{
    color: #00dbe1;
}
ul{
    overflow: hidden;
    padding-left: 10px;
}
.List_3{
    margin-top: 30px;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: top;
    /* width: 25%; */
    font-size: 18px;
}
.List_3 .M_cyan{
    font-size: 26px;
    margin-left: 27px;
    margin-top: 5px;
}
.List_3 .iconfont{
    font-size: 22px;
    margin-right: 5px;
}

.List_2{
    margin-top: 30px;
    margin-bottom: 10px;
    float: left;
    width: 50%;
    font-size: 18px;
}
.List_2 .imgBox{
    display: inline-block;
    vertical-align: top;
    width: 60px;
}
.List_2 .imgBox img{
    width: 100%;
}

.List_2 .wordBox{
    display: inline-block;
    vertical-align: top;
    margin-left: 15px;
    
}

.List_1{
    margin: 25px 0px 0px 0px;
}
.List_1 .M_cyan{
    font-size: 20px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}

.dataCenter .haed{
    position: relative;
    text-align: center;
    margin: 0px 40px 20px 40px;
    height: 90px;
    background-image: linear-gradient(to right,#021033,#30a2c2,#021033);
}
.dataCenter .headTit{
    font-size: 34px;
    line-height: 115px;
    font-weight: bold;
}

.dataCenter .haedTransform{
    background-image: linear-gradient(to top, #021235 0%,#309dbc 50%, #021235 100%);
    width: 170px;
    height: 50px;
    text-align: center;
    position: absolute;
    bottom: 0;
    border: #020f33 solid 10px;
}
.dataCenter .haedTransform span{
    position: absolute;
    font-size: 21px;
    width: 100%;
    bottom: 0;
}

.dataCenter .headWeather{
    transform: skewX(45deg);
    left: 0;
}
.dataCenter .headWeather span{
    transform: skewX(-45deg);
    left: 0;
}
.dataCenter .headWeather .iconfont{
    font-size: 30px;
    margin-right: 5px;
}
.dataCenter .headWeather .weather{
    display: inline-block;
}

.dataCenter .headDate{
    transform: skewX(-45deg);
    right: 0;
}
.dataCenter .headDate span{
    transform: skewX(45deg);
    right: 0;
}

.notice{
    overflow: hidden;
    margin: 30px 50px 10px 50px;
}
.noticeBtn{
    float: left;
    width: 110px;
    height: 48px;
    background:radial-gradient(#061a41,#2380a7);
    border-radius: 20px;
    text-align: center;
    line-height: 48px;
    font-size: 22px;
    font-weight: bold;
}
.noticeWord{
    float: left;
    width: calc(100% - 160px);
    height: 48px;
    line-height: 48px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 40px;
    background-image: linear-gradient(to right,#021033,#30a2c2,#021033);
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}

.earlyWarn{
    overflow: hidden;
    margin: 20px 50px 10px 50px;
}
.earlyWarnBtn{
    float: left;
    width: 110px;
    height: 48px;
    background:radial-gradient(#250f30,#8d0719);
    border-radius: 20px;
    text-align: center;
    line-height: 48px;
    font-size: 22px;
    font-weight: bold;
}
.earlyWarnWord{
    float: left;
    width: calc(100% - 160px);
    height: 48px;
    line-height: 48px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 40px;
    background-image: linear-gradient(to right,#021033,#b30513,#021033);
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}

.ycPlayer{
    margin: 20px 50px 20px 50px;
    height: 600px;
    border:3px solid;
    border-image: linear-gradient(to right,#010f43,#0070f5,#eef0f7,#0070f5,#010f43)30 30;
    position: relative;
}
.ycPlayer #muiPlayer{
    width: 100%;
    height: 100%;
    z-index: 99;
}
.ycPlayer .playerBorL{
    width: 100%;
    height: 100%;
    border:3px solid;
    border-image: linear-gradient(to top,#010f43,#0070f5,#eef0f7,#0070f5,#010f43)30 30;
    position: absolute;
    top: 0;
    left: -3px;
}
.ycPlayer .playerMsg{
    border: #01fdfd solid 2px;
    background: rgba(1,253,253,0.2);
    position: absolute;
    width: 150px;
    padding: 15px;
    top: 50%;
    left: 20%;
}
.ycPlayer .playerMsg .tit{
    font-size: 18px;
}
.ycPlayer .playerMsg .word{
    padding: 5px 0px 0px 0px;
}

.DataMonitor{
    margin: 20px 50px 20px 50px;
    border:#003e7e solid 1px;
    position: relative;
}
.DataMonitor .cont{
    position: absolute;
    width: 20px;
    height: 15px;
}
.DataMonitor .topleft{
    left: 0;
    top: 0;
    border-left: #00cdd5 solid 2px;
    border-top: #00cdd5 solid 2px;
}
.DataMonitor .topright{
    right: 0;
    top: 0;
    border-right: #00cdd5 solid 2px;
    border-top: #00cdd5 solid 2px;
}
.DataMonitor .bottomleft{
    left: 0;
    bottom: 0;
    border-left: #00cdd5 solid 2px;
    border-bottom: #00cdd5 solid 2px;
}
.DataMonitor .bottomright{
    right: 0;
    bottom: 0;
    border-right: #00cdd5 solid 2px;
    border-bottom: #00cdd5 solid 2px;
}
.DataMonitor .listBox{
    margin: 30px 10px 30px 10px;
}
.DataMonitor .listBox li{
    display: inline-block;
    vertical-align: top;
    width: 16%;
    text-align: center;
}
.DataMonitor .listBox li .iconfont{
    font-size: 38px;
    line-height: 60px;
    color: #00dbe1;
}
.DataMonitor .listBox li .title{
    font-size: 20px;
    margin: 30px 0px 10px 0px;
}
.DataMonitor .listBox li .type{
    font-size: 20px;
    margin: 10px 0px 10px 0px;
    color: #00dbe1;
}

/**四角边框*/
.borderBox{
    display: inline-block;
    vertical-align: top;
    width: 60px;
    height: 60px;
    background: #033551;
    position: relative;
    text-align: center;
}
.List_2 .iconfont{
    display: block;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
}
.borderBox .cont{
    position: absolute;
    width: 8px;
    height: 5px;
}
.borderBox .topleft{
    left: 0;
    top: 0;
    border-left: #00cdd5 solid 1px;
    border-top: #00cdd5 solid 1px;
}
.borderBox .topright{
    right: 0;
    top: 0;
    border-right: #00cdd5 solid 1px;
    border-top: #00cdd5 solid 1px;
}
.borderBox .bottomleft{
    left: 0;
    bottom: 0;
    border-left: #00cdd5 solid 1px;
    border-bottom: #00cdd5 solid 1px;
}
.borderBox .bottomright{
    right: 0;
    bottom: 0;
    border-right: #00cdd5 solid 1px;
    border-bottom: #00cdd5 solid 1px;
}

.dataRmap{
    height: 300px;
    border:#0070f8 solid 2px;
    background: #1b379c;
    margin-top: 30px;
}
.plantData{
    margin-top: 20px;
    margin-left: 10px;
}
.plantData li{
    line-height: 50px;
    font-size: 18px;
}
.plantData li .data{
    color: #00dbe1;
}
.cropChart{
    height: 200px;
}
</style>

