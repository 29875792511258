<template>
  <div class="upload-container">
    <el-button :style="{background:color,borderColor:color}" icon="el-icon-upload" size="mini" type="primary"
               @click=" dialogVisible=true">上传图片
    </el-button>
    <el-dialog :visible.sync="dialogVisible"append-to-body>
      <el-upload
        :multiple="true"
        :file-list="fileList"
        :show-file-list="true"
		:http-request="customUpload"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :before-upload="beforeUpload"
        class="editor-slide-upload"
		:on-error="handleUploadPicError"
        :headers="uploadHeaders"
        :action="fileUrl"
        list-type="picture-card">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </el-dialog>
  </div>
</template>

<script>
  // import { getApiUrl } from '@/utils/utils'
  // import { getToken } from '@/utils/auth'
  export default {
    name: 'EditorSlideUpload',
    props: {
      color: {
        type: String,
        default: '#1890ff'
      }
    },
    data() {
      return {
        dialogVisible: false,
        listObj: {},
        fileList: [],
        fileUrl: '',
        uploadHeaders: {
          'Authorization': ''
        },
		uploadUrl:''
      }
    },
    created() {
      // this.fileUrl = getApiUrl() + '/file'
      // this.uploadHeaders['Authorization'] = getToken()
	  this.uploadUrl = '/shop_img/mup_file_img'
    },
    methods: {
		customUpload(request){
			const formData = new FormData();
			this.uploadLoad = this.$loading({
			  lock: true,
			  text: '上传中...请等待',
			  spinner: 'el-icon-loading',
			  background: 'rgba(0, 0, 0, 0.7)'
			});
			
			  formData.append('file', request.file); //这里的file是必须的
			  formData.append('uid',  sessionStorage.getItem('User_ID')); //这里的file是必须的
			  formData.append('show_type', 'shop_goods_thumbnail'); //这里的file是必须的
		 
			  // 自定义上传行为，这里使用了axios，你可以根据实际情况使用其他HTTP库
			  this.$http({
				method: 'post',
				url: this.uploadUrl,
				data: formData,
				headers: {
				  'Content-Type': 'multipart/form-data',
			  "userInfo": this.userInfo
				}
			  }).then(response => {
				// 上传成功的处理逻辑
				console.log('Upload success:', response);
				request.onSuccess(response.data);
			  }).catch(error => {
				// 上传失败的处理逻辑
				console.log('Upload fail:', error);
				request.onError(error);
			  });
		},
		handleUploadPicError(response) {
		    this.$message({
		      message: '上传失败',
		      type: 'error'
		    })
			this.uploadLoad.close();
		},
      checkAllSuccess() {
        return Object.keys(this.listObj).every(item => this.listObj[item].hasSuccess)
      },
      handleSubmit() {
        const arr = Object.keys(this.listObj).map(v => this.listObj[v])
        if (!this.checkAllSuccess()) {
          this.$message('请等待所有图片上传成功 或 出现了网络问题，请刷新页面重新上传！')
          return
        }
        this.$emit('successCBK', arr)
        this.listObj = {}
        this.fileList = []
        this.dialogVisible = false
      },
      handleSuccess(response, file) {
        const uid = file.uid
        const objKeyArr = Object.keys(this.listObj)
        for (let i = 0, len = objKeyArr.length; i < len; i++) {
          if (this.listObj[objKeyArr[i]].uid === uid) {
            // this.listObj[objKeyArr[i]].url = this.fileUrl + '/download?fileName=' + response.data.realFileName
            this.listObj[objKeyArr[i]].url = ApiUrl_My + response.meta.app_url
            this.listObj[objKeyArr[i]].hasSuccess = true
			this.uploadLoad.close();
            return
          }
        }
		
      },
      handleRemove(file) {
        const uid = file.uid
        const objKeyArr = Object.keys(this.listObj)
        for (let i = 0, len = objKeyArr.length; i < len; i++) {
          if (this.listObj[objKeyArr[i]].uid === uid) {
            delete this.listObj[objKeyArr[i]]
            return
          }
        }
      },
      beforeUpload(file) {
        const _self = this
        const _URL = window.URL || window.webkitURL
        const fileName = file.uid
        this.listObj[fileName] = {}
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.src = _URL.createObjectURL(file)
          img.onload = function() {
            _self.listObj[fileName] = { hasSuccess: false, uid: file.uid, width: this.width, height: this.height }
          }
          resolve(true)
        })
      }
    }
  }
</script>

<style scoped>
  .editor-slide-upload {
    margin-bottom: 20px;

  }
  .editor-slide-upload /deep/ .el-upload--picture-card{
	  width: 100%;
  }
</style>
