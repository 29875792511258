import editorImage from '@/components/goods/Tinymce'
import plugins from '@/components/editContainer/plugins'
import toolbar from '@/components/editContainer/toolbar'
// import goodsApi from '@/api/shop/goods'
// import attrValApi from '@/api/shop/attrVal'
// import categoryApi from '@/api/shop/category'
// import {getApiUrl} from '@/utils/utils'
// import {getToken} from '@/utils/auth'
// import goodsSku from "../../../api/shop/goodsSku";

export default {
  name:'goodsEdit',
  components: {editorImage},
  props: {
    id: {
      type: String,
      default: function () {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
	editId: {
      type: String,
      default: null
    } ,
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: Number,
      required: false,
      default: 360
    }
  },
  data() {
    return {
      spec: 'one',
      specs: [],
      specDialogFormVisible: false,
      skuForm: {},
      attrKeySel: '',
      attrKeyList: [],
      attrValList: [],
      showAddAttrKey: false,
      attrKeyForm: {attrName: ''},
      attrValForm: {attrVal: ''},
      attrValSel: '',
      attrValListSel: [],
      tags: [],
      form: {
        pic: '',
        gallery: [],
        idCategory: '',
        isHot: false,
        isNew: false
      },
      skuList: [],
      uploadUrl: '',
      uploadFileId: '',
      uploadHeaders: {
        'Authorization': ''
      },
      idGoods: '',
      active: 0,
      categories: [],
      tinymceId: 'tinymceId',
      fullscreen: false,
      languageTypeList: {
        'en': 'en',
        'zh': 'zh_CN'
      },
      galleryList: [],
      // apiUrl: getApiUrl(),
	  isSaveBtn:false,
	  uploadLoad:null,
	  userInfo:null,
	  operationMode:'add'
    }
  },
  // computed: {
    // language() {
    //   return this.languageTypeList[this.$store.getters.language]
    // },
  // },
  watch: {
    value(val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
    },
    // language() {
    //   this.destroyTinymce()
    //   this.$nextTick(() => this.initTinymce())
    // },
	editId: {
		handler:function (val,oldVal) {
			this.idGoods = val
			this.fetchData()
		},
		immediate: true,
		deep:true
	}
  },
  mounted() {
	  this.userInfo = encodeURIComponent(sessionStorage.getItem('roleObj'));
    this.init()
    this.initTinymce()
  },
  activated() {
    this.initTinymce()
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    init() {
      this.uploadUrl = '/shop_img/mup_file_img'
      // this.uploadHeaders['Authorization'] = getToken()
      
    },
    fetchData() {
      this.listLoading = true
	  console.log("jhjhjjjjj");
      if (this.idGoods) {
		  this.operationMode = "modify"
		  // /shop/goods?id=319305
		  
		  const formData = new FormData();
		  formData.append('id',this.idGoods); 
		  this.$http({
		    method: "get",
		    //url: "http://inside.cloudcyz.com:8000/mlogin",
		    url: "/shop/goods?id="+this.idGoods,
		    data: formData,
		    headers: {
		      'Content-Type': 'multipart/form-data',
		  "userInfo": this.userInfo
		    },
		  }).then(response => {
			  console.log(response);
		    this.form = response.data.data.goods
		    this.form.price = this.form.price / 100
		    this.form.marketingPrice = this.form.marketingPrice / 100
		    this.idGoods = this.form.id
		    this.skuList = response.data.data.skuList
		    this.spec = this.skuList.length > 0 ? 'more' : 'one'
		    let galleryArr = this.form.gallery.split(',')
		    for (let i = 0; i < galleryArr.length; i++) {
		      if (galleryArr[i] != '') {
		        this.galleryList.push({
		          id: galleryArr[i],
		          url: galleryArr[i]
		        })
		      }
		    }
			
		    this.setContent(this.form.detail)
			
			this.$http({
			  method: "get",
			  //url: "http://inside.cloudcyz.com:8000/mlogin",
			  url: "/shop/attr/val/getAttrByCategoryAndGoods/1",
			  // data: formData,
			  headers: {
			    'Content-Type': 'multipart/form-data',
		  "userInfo": this.userInfo
			  },
			}).then(response2 => {
			  this.attrKeyList = response2.data.data.keyList
			  this.attrValList = response2.data.data.valList
			})
			
		  })

      }else{
		  this.operationMode = "add"
		  Object.assign(this.$data.form, this.$options.data().form)
		  Object.assign(this.$data.skuList, this.$options.data().skuList)
		  if(this.idGoods){
			  Object.assign(this.$data.idGoods, this.$options.data().idGoods)
		  }
		  // Object.assign(this.$data.spec, this.$options.data().spec)
	  }
	  
	  this.$http({
	    method: "GET",
	    url: "/shop/category/list",
	    headers: {
	      "Content-type": "application/x-www-form-urlencoded",
		  "userInfo": this.userInfo
	    },
	  }).then(response => {
	  		  console.log("response===================",response);
	    // this.list = response.data.data.records
	    this.categories = response.data.data
	  })
	  
    },
    prev() {
      if (this.active > 0) {
        this.active -= 1
      }
    },
    save() {
		if (!this.idGoods){
			//第一步提交的时候先保存下商品以便获取商品id
			if (this.form.name === '' ||
			  this.form.idCategory === '' ||
			  this.form.descript === '') {
			  this.$message({
			    message: '请输入必要的商品项目',
			    type: 'error'
			  })
			  return
			}
			
			this.$http({
			  method: "post",
			  //url: "http://inside.cloudcyz.com:8000/mlogin",
			  url: "/shop/goods/saveBaseInfo",
			  data: {
				  name: this.form.name,
				  idCategory: this.form.idCategory,
				  descript: this.form.descript,
				  isNew: this.form.isNew,
				  isHot: this.form.isHot
				},
			  headers: {
				"userInfo": this.userInfo
			  },
			}).then(response2 => {
			  this.idGoods = response2.data.data
			  this.isSaveBtn = true
			})
			
			// goodsApi.saveBaseInfo({
			//   name: this.form.name,
			//   idCategory: this.form.idCategory,
			//   descript: this.form.descript,
			//   isNew: this.form.isNew,
			//   isHot: this.form.isHot
			// }).then(response => {
			//   this.idGoods = response.data
			//   this.isSaveBtn = true
			// })
		}else{
			this.isSaveBtn = true
		}
        
		
		this.$http({
		  method: "get",
		  //url: "http://inside.cloudcyz.com:8000/mlogin",
		  url: "/shop/attr/val/getAttrByCategoryAndGoods/1",
		  // data: formData,
		  headers: {
		    'Content-Type': 'multipart/form-data',
		  "userInfo": this.userInfo
		  },
		}).then(response2 => {
		  this.attrKeyList = response2.data.data.keyList
		  this.attrValList = response2.data.data.valList
		})
        // attrValApi.getAttrBy(this.form.idCategory).then(response2 => {
        //   this.attrKeyList = response2.data.keyList
        //   this.attrValList = response2.data.valList
        // })
    },
	saveGoods(){
		
		const content = this.getContent()
		const gallery = this.getGallery()
		
		
		if (this.spec === 'one') {
			
			if (!this.form.stock) {
			  this.$message({
			    message: '请输入库存数量',
			    type: 'error'
			  })
			  return
			}
			if (!this.form.marketingPrice) {
			  this.$message({
			    message: '请输入市场价',
			    type: 'error'
			  })
			  return
			}
			if (!this.form.price) {
			  this.$message({
			    message: '请输入价格',
			    type: 'error'
			  })
			  return
			}
		}
			
		
		
		
		if (this.spec === 'more') {
		  //如果商品配置多规格，则删除单规格配置
		  this.form.price = ''
		  this.form.marketingPrice = ''
		  this.form.stock = ''
		}
		var price = this.form.price * 100;
		var marketingPrice = this.form.marketingPrice * 100;
		
		
		this.$http({
		  method: "post",
		  //url: "http://inside.cloudcyz.com:8000/mlogin",
		  url: "/shop/goods",
		  data: {
			  name: this.form.name,
			  pic: this.form.pic,
			  gallery: gallery,
			  idCategory: this.form.idCategory,
			  descript: this.form.descript,
			  detail: content,
			  stock: this.form.stock,
			  price: price,
			  isDelete: typeof this.form.isDelete == 'undefined' ? false : this.form.isDelete,
			  isOnSale: typeof this.form.isOnSale  == 'undefined' ? false : this.form.isOnSale,
			  id: this.idGoods,
			  isNew: this.form.isNew,
			  isHot: this.form.isHot,
			  marketingPrice:marketingPrice,
			  OperationMode:this.operationMode
			},
		  headers: {
			// 'Content-Type': 'application/json'
			"userInfo": this.userInfo
		  },
		}).then(response2 => {
		 this.$message({
		   message: 'common.optionSuccess',
		   type: 'success'
		 })
		 // this.$router.push('/goods')
		 this.isSaveBtn = false
		 			this.$emit('cacelShop')
		})
		
		// goodsApi.save({
		//   name: this.form.name,
		//   pic: this.form.pic,
		//   gallery: gallery,
		//   idCategory: this.form.idCategory,
		//   descript: this.form.descript,
		//   detail: content,
		//   stock: this.form.stock,
		//   price: price,
		//   isDelete: this.form.isDelete,
		//   isOnSale: this.form.isOnSale,
		//   id: this.idGoods,
		//   isNew: this.form.isNew,
		//   isHot: this.form.isHot
		// }).then(response => {
		//   this.$message({
		//     message: 'common.optionSuccess',
		//     type: 'success'
		//   })
		//   // this.$router.push('/goods')
		//   this.isSaveBtn = false
		// 	this.$emit('cacelShop')
		// })
	},
	cacelGood(){
		this.$emit('cacelShop')
	},

    getGallery() {
      let gallery = ''
      for (let i = 0; i < this.galleryList.length; i++) {
        if (i == 0) {
          gallery = this.galleryList[i].id
        } else {
          gallery += (',' + this.galleryList[i].id)
        }

      }
      return gallery
    },
    handleRemove: function (file, fileList) {
      for (var i = 0; i < this.galleryList.length; i++) {
        if (this.galleryList[i].id === file.id) {
          this.galleryList.splice(i, 1)
        }
      }

    },
    handleUploadPicSuccess(response, raw) {
      if (response.meta.status) {
        this.form.pic = ApiUrl_My + response.meta.app_url
      } else {
        this.$message({
          message: 'common.uploadError',
          type: 'error'
        })
      }
	  this.uploadLoad.close();
    },
	handleUploadPicError(response) {
        this.$message({
          message: '上传失败',
          type: 'error'
        })
		this.uploadLoad.close();
    },
	customUpload(request){
		const formData = new FormData();
		this.uploadLoad = this.$loading({
		  lock: true,
		  text: '上传中...请等待',
		  spinner: 'el-icon-loading',
		  background: 'rgba(0, 0, 0, 0.7)'
		});
		
		  formData.append('file', request.file); //这里的file是必须的
		  formData.append('uid',  sessionStorage.getItem('User_ID')); //这里的file是必须的
		  formData.append('show_type', 'shop_goods_thumbnail'); //这里的file是必须的
	 
		  // 自定义上传行为，这里使用了axios，你可以根据实际情况使用其他HTTP库
		  this.$http({
			method: 'post',
			url: this.uploadUrl,
			data: formData,
			headers: {
			  'Content-Type': 'multipart/form-data',
		  "userInfo": this.userInfo
			}
		  }).then(response => {
			// 上传成功的处理逻辑
			console.log('Upload success:', response);
			request.onSuccess(response.data);
		  }).catch(error => {
			// 上传失败的处理逻辑
			console.log('Upload fail:', error);
			request.onError(error);
		  });
	},
	customUpload1(request){
		const formData = new FormData();
		this.uploadLoad = this.$loading({
		  lock: true,
		  text: '上传中...请等待',
		  spinner: 'el-icon-loading',
		  background: 'rgba(0, 0, 0, 0.7)'
		});
		
		  formData.append('file', request.file); //这里的file是必须的
		  formData.append('uid',  sessionStorage.getItem('User_ID')); //这里的file是必须的
		  formData.append('show_type', 'goods_album'); //这里的file是必须的
	 
		  // 自定义上传行为，这里使用了axios，你可以根据实际情况使用其他HTTP库
		  this.$http({
			method: 'post',
			url: this.uploadUrl,
			data: formData,
			headers: {
			  'Content-Type': 'multipart/form-data',
		  "userInfo": this.userInfo
			}
		  }).then(response => {
			// 上传成功的处理逻辑
			console.log('Upload success:', response);
			request.onSuccess(response.data);
		  }).catch(error => {
			// 上传失败的处理逻辑
			console.log('Upload fail:', error);
			request.onError(error);
		  });
	},
	
	
	handleUploadGalleryError(response) {
        this.$message({
          message: '上传失败',
          type: 'error'
        })
		this.uploadLoad.close();
    },
    handleUploadGallerySuccess(response, raw) {
		if (response.meta.status) {
			this.galleryList.push(
			  {
			    id: response.meta.app_url,
			    url: ApiUrl_My + response.meta.app_url
			  }
			)
		} else {
		  this.$message({
		    message: 'common.uploadError',
		    type: 'error'
		  })
		}
		this.uploadLoad.close();
    },

    initTinymce() {
      const _this = this
      window.tinymce.init({
        value: '',
        language: this.language,
        selector: `#${this.tinymceId}`,
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: this.menubar,
        plugins: plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'clean',
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        default_link_target: '_blank',
        link_title: false,
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: editor => {
          if (_this.value) {
            editor.setContent(_this.value)
          }
          _this.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            this.hasChange = true
            const content = editor.getContent()
            this.form.content = content
          })
        },
        setup(editor) {
          editor.on('FullscreenStateChanged', (e) => {
            _this.fullscreen = e.state
          })
        }
      })
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      return window.tinymce.get(this.tinymceId).getContent()
    },
    imageSuccessCBK(arr) {
      const _this = this
      arr.forEach(v => {
        window.tinymce.get(_this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`)
      })
    },
    tableRowClassName(row, index) {
      if (index === 1) {
        return 'info-row'
      } else if (index === 3) {
        return 'positive-row'
      }
      return ''
    },
    changeAttrKey(val) {
      let attrValSel = []
      for (let i = 0; i < this.attrValList.length; i++) {
        if (this.attrValList[i].idAttrKey === val) {
          attrValSel.push(this.attrValList[i])
        }
      }

      this.attrValListSel = attrValSel
      this.attrValSel = attrValSel[0].id
    },
    addAttrKeyFun() {
      this.showAddAttrKey = !this.showAddAttrKey
    },
    addToTag() {
      for (let i = 0; i < this.attrValList.length; i++) {
        if (this.attrValList[i].id === this.attrValSel) {
          const currentAttrVal = this.attrValList[i]
          let isRepeat = false
          let isRepeatAttrKey = false
          this.tags.forEach(function (val) {
            if (val.id === currentAttrVal.id) {
              isRepeat = true
            }
            if (val.idAttrKey === currentAttrVal.idAttrKey) {
              isRepeatAttrKey = true
            }
          })
          if (isRepeat === false && isRepeatAttrKey === false) {
            this.tags.push(currentAttrVal)
          } else {
            this.$message({
              message: isRepeat == true ? '请勿选择重复规格' : '该属性已经选择一种规格值',
              type: 'error'
            })
          }
          break
        }
      }
    },
    removeTag(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
    },
    submitAttrKeyForm() {
      if (this.attrKeyForm.attrName != '') {
        let duplicationKey = false;
        for (let i = 0; i < this.attrKeyList.length; i++) {
          if (this.attrKeyForm.attrName === this.attrKeyList[i].attrName) {
            duplicationKey = true;
            break;
          }
        }
        if (!duplicationKey) {
          const id = this.attrKeyList.length + 1;
          this.attrKeyList.push({
            id: id,
            attrName: this.attrKeyForm.attrName
          })
          this.attrKeySel = id;
          this.addAttrKeyFun();
        }
      }
      //提交属性名
      //提交成功后，将属性名自动选择当前属性名
      //提交成功后自动收起添加属性名表单，并且将并单清空重置
    },
    openAddSkuForm() {
      this.tags = []
      this.specDialogFormVisible = true
    },
    closeAddSkuForm() {
      this.specDialogFormVisible = false
    },
    getSkuCode() {
      let code = ''
      let codeName = ''
      for (let i = 0; i < this.tags.length; i++) {
        if (i === 0) {
          code = this.tags[i].id
          codeName = this.tags[i].attrVal
        } else {
          code += ',' + this.tags[i].id
          codeName += ',' + this.tags[i].attrVal
        }
      }
      return {code: code, codeName: codeName}
    },
    addSku() {

      if (this.tags.length == 0) {
        this.$message({
          message: '请选择商品规格',
          type: 'error'
        })
        return
      }
      if (!this.skuForm.stock) {
        this.$message({
          message: '请输入库存数量',
          type: 'error'
        })
        return
      }
      if (!this.skuForm.marketingPrice) {
        this.$message({
          message: '请输入市场价',
          type: 'error'
        })
        return
      }
      if (!this.skuForm.price) {
        this.$message({
          message: '请输入价格',
          type: 'error'
        })
        return
      }
      const skuCodeAndName = this.getSkuCode()
	  var marketingPrice = this.skuForm.marketingPrice * 100;
	  var price = this.skuForm.price * 100;
	  
	  const formData = new FormData();
	  formData.append('idGoods', this.idGoods); 
	  formData.append('marketingPrice', marketingPrice);
	  formData.append('code', skuCodeAndName.code);
	  formData.append('codeName', skuCodeAndName.codeName);
	  formData.append('price', price);
	  formData.append('stock', this.skuForm.stock);
	  
	  this.$http({
	    method: "POST",
	    //url: "http://inside.cloudcyz.com:8000/mlogin",
	    url: "/shop/goods/sku",
		data:formData,
	    headers: {
			'Content-Type': 'multipart/form-data',
	  		  "userInfo": this.userInfo
	    },
	  }).then(response => {
	  		 let sku = response.data.data
	  		 let updateOldSku = false
	  		 for (let i = 0; i < this.skuList.length; i++) {
	  		   if (this.skuList[i].id === sku.id) {
	  		     this.skuList.splice(i, 1, sku)
	  		     updateOldSku = true
	  		     break;
	  		   }
	  		 }
	  		 if (!updateOldSku) {
	  		   this.skuList.push(sku)
	  		 }
	  		 this.specDialogFormVisible = false
	  })
	  
      // goodsSku.save({
      //   idGoods: this.idGoods,
      //   marketingPrice: marketingPrice,
      //   code: skuCodeAndName.code,
      //   codeName: skuCodeAndName.codeName,
      //   price: price,
      //   stock: this.skuForm.stock
      // }).then(response => {

      //   let sku = response.data
      //   let updateOldSku = false
      //   for (let i = 0; i < this.skuList.length; i++) {
      //     if (this.skuList[i].id === sku.id) {
      //       this.skuList.splice(i, 1, sku)
      //       updateOldSku = true
      //       break;
      //     }
      //   }
      //   if (!updateOldSku) {
      //     this.skuList.push(sku)
      //   }
      //   this.specDialogFormVisible = false
      // })
    },
    removeSku(index) {
      console.log(index)
      const record = this.skuList[index]
      // goodsSku.remove(record.id)
	  const formData = new FormData();
	  formData.append('id', record.id); 
	  this.$http({
	    method: "DELETE",
	    //url: "http://inside.cloudcyz.com:8000/mlogin",
	    url: "/shop/goods/sku",
	  	data:formData,
	    headers: {
	  			// 'Content-Type': 'multipart/form-data',
	  		  "userInfo": this.userInfo
	    },
	  }).then(response => {
	  		 this.skuList.splice(index, 1)
	  })
	  
    }

  }
}
