<template>
  <div class="app">
    <div class="container switch">
      <!-- 注册页面 -->
      <div class="container-form container-signup">
        <el-form action="" class="form" :model="ruleForm" ref="ruleForm">
          <h2 class="form-title">注册</h2>
          <input
            type="text"
            v-model="ruleForm.user"
            placeholder="User"
            class="input"
          />
          <input
            type="password"
            v-model="ruleForm.pwd1"
            placeholder="Password"
            class="input"
          />
          <input
            type="password"
            v-model="ruleForm.pwd2"
            placeholder="Confirm password"
            class="input"
          />
          <button class="submit" @click="submitForm('ruleForm')">注册</button>
        </el-form>
      </div>
      <!-- 登录页面 -->
      <div class="container-form container-signin">
        <form
          action="javascript:void(0);"
          class="form"
          :model="loginForm"
          :rules="loginFormRules"
          ref="loginFormRef"
        >
          <h2 class="form-title">登录</h2>
          <input
            type="text"
            v-model="loginForm.username"
            placeholder="User"
            class="input"
            prop="username"
			@input="phoneVal()"
          />
          <input
            type="password"
            v-model="loginForm.password"
            placeholder="Password"
            class="input"
          />
			
			<div class="selectDiy" style="display: flex;">
				<el-select v-model="roleVal" placeholder="请选择角色" :disabled="phoneDis"  value-key="role_tab_id" >
				    <el-option
				      v-for="item in loginTypeObj.role"
				      :key="item.role_tab_id"
				      :label="item.role_name"
				      :value="item">
				    </el-option>
				  </el-select>
				 <!-- <el-select v-model="cropsVal" placeholder="请选择作物" :disabled="phoneDis"  value-key="tab_id" >
				      <el-option
				        v-for="item in loginTypeObj.crops"
				        :key="item.tab_id"
				        :label="item.name"
				        :value="item">
				      </el-option>
				    </el-select> -->
			</div>
			
          <button class="submit" @click="login">登录</button>
          <a class="aed" @click="dialogFormVisible = true">重置密码</a>

        </form>
      </div>
      <!-- 覆盖层 -->
      <div class="container-overlay">
        <div class="overlay">
          <div class="overlay-slide overlay-left">
            <button class="submit" id="goSignIn">注册</button>
          </div>
          <div class="overlay-slide overlay-right">
            <button class="submit" id="goSignUp">登录</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible">
  <el-form :model="editForm">
    <el-form-item label="用户名或手机号" :label-width="formLabelWidth">
      <el-input v-model="editForm.user" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="旧密码" :label-width="formLabelWidth">
      <el-input v-model="editForm.old_pwd" autocomplete="off" type="password"></el-input>
    </el-form-item>
    <el-form-item label="新密码" :label-width="formLabelWidth">
      <el-input v-model="editForm.pwd1" autocomplete="off" type="password"></el-input>
    </el-form-item>
    <el-form-item label="确认密码" :label-width="formLabelWidth">
      <el-input v-model="editForm.pwd2" autocomplete="off" type="password"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="editpassword">确 定</el-button>
  </div>
</el-dialog>
  </div>


</template>

<script>
window.addEventListener("load", () => {
  // 我们需要获取两个切换按钮 因此需要到html结构中 定义特殊类名或id名
  const goSignIn = document.querySelector("#goSignIn");
  const goSignUp = document.querySelector("#goSignUp");
  // 获取被切换的样式类
  const container = document.querySelector(".container");
  // 添加点击事件
  goSignIn.addEventListener("click", () => {
    container.classList.remove("switch");
  });
  goSignUp.addEventListener("click", () => {
    container.classList.add("switch");
  });
 
});
export default {
  data() {
    var validatepwd1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.pwd2 !== "") {
          this.$refs.ruleForm.validateField("pwd2");
        }
        callback();
      }
    };
    var validatepwd2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pwd1) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var checkuser = (rule, value, cb) => {
      const reguser =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (reguser.test(value)) {
        return cb();
      }
      cb(new Error("请输入正确手机号"));
    };

    return {
		loginTypeObj:{},
		roleVal:"",
		cropsVal:"",
      formLabelWidth: '120px',
       dialogTableVisible: false,
        dialogFormVisible: false,
      ruleForm: {
        user: "",
        pwd1: "",
        pwd2: "",
      },
      rules: {
        pwd1: [{ validator: validatepwd1, trigger: "blur" }],
        pwd2: [{ validator: validatepwd2, trigger: "blur" }],
        user: [
          { required: true, message: "输入手机号", trigger: "blur" },
          {
            validator: checkuser,
            trigger: "blur",
          },
        ],
      },
      zhuceDialogVisible: false,
      zhuceForm: {
        user: "",
        pwd1: "",
        pwd2: "",
      },
      editForm: {
        user: "",
        pwd1: "",
        pwd2: "",
        old_pwd:''
      },
      // 登录表单的数据绑定对象
      loginForm: {
        username: "",
        password: "",
      },
      // 表单验证过规则对象
      loginFormRules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
	  phoneDis:true
    };
  },
  activated(){
	  console.log("ddd");
    // this.loginType();
  },
  methods: {
    // 点击重置按钮重置登录表单
    resetloginForm() {
      this.$refs.loginFormRef.resetFields();
    },
	
	phoneVal(){
		if(this.loginForm.username.length == 11){
			this.phoneDis = false;
			this.loginType()
		}else{
			this.phoneDis = true;
			this.roleVal=""
			this.cropsVal=""
		}
	},
    // 登录
    login() {
    console.log(this.$router);
    console.log(this.roleVal);
    console.log(this.cropsVal);
	
	if(this.roleVal == ""){
		this.$message.error("请选择角色");
		return ;
	}
	// if(this.cropsVal == ""){
	// 	this.$message.error("请选择作物");
	// 	return ;
	// }
      this.$http({
        method: "post",
        //url: "http://inside.cloudcyz.com:8000/mlogin",
        url: "/mlogin",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        data: this.loginForm,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        // console.log(res.data.meta.status);
        // console.log(this.loginForm);
        console.log('data.meta:',res.data.meta.status);
        console.log('data.user_info:',res.data);
        sessionStorage.setItem('TOKEN',res.data.meta.token)
        if (res.data.meta.status != true)
          return this.$message.error(res.data.meta.msg);
        this.$message.success(res.data.meta.msg);
        sessionStorage.setItem('User_ID',res.data.meta.user_info.user_id);
        sessionStorage.setItem('phone',this.loginForm.username);
        sessionStorage.setItem('roleObj',JSON.stringify(this.roleVal) );
        // sessionStorage.setItem('cropsObj',JSON.stringify(this.cropsVal));
        console.log('sessionStorage.setItem',sessionStorage.getItem('User_ID'));
        console.log('sessionStorage.setItem',sessionStorage.getItem('TOKEN'));
        this.$router.replace("./home");
      });

    },
	// 登录
	loginType() {
	console.log(this.$router);
	// 创建FormData实例
	const formData = new FormData();
	 
	// 可以添加其他表单字段
	formData.append('user_phone', this.loginForm.username);
	  this.$http({
	    method: "post",
	    url: "/login_type",
	    headers: {
	      "Content-type": "multipart/form-data",
	    },
	    data: formData,
	  }).then((res) => {
	    // console.log(res.data.meta.status);
	    // console.log(this.loginForm);
		this.loginTypeObj = res.data
		console.log(this.loginTypeObj);
	  });
	
	},
    zhuce() {
      this.zhuceDialogVisible = true;
    },
    addDialogclosed() {
      this.$refs.zhuceFormRef.resetFields();
    },
    zhucefrominfo() {
      this.$http({
        method: "post",
        url: "mreg",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        data: this.zhuceForm,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        console.log(res.data);
        console.log(this.zhuceForm);
        if (res.data.meta.stat != true) return this.$message.error("登录失败 ");
        this.$message.success("登录成功");
        this.$router.push("./home");
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return;
        this.$http({
          method: "post",
          url: "mreg",
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
          data: this.ruleForm,
          transformRequest: [
            function (data) {
              let ret = "";
              for (let it in data) {
                ret +=
                  encodeURIComponent(it) +
                  "=" +
                  encodeURIComponent(data[it]) +
                  "&";
              }
              return ret;
            },
          ],
        }).then((res) => {
          console.log(res.data);
          this.$message(res.data.meta.msg);
        });
        // if(res.data.meta.status== -1){
        // this.$message("已注册");

        // }
      });
    },
    editpassword(){
     this.dialogFormVisible = false
     this.$http({
        method: "post",
        url: "mide_pwd",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        data: this.editForm,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        console.log(res);
        // console.log(this.zhuceForm);
        // if (res.data.meta.stat != true) return this.$message.error("登录失败 ");
        this.$message.success(res.data.meta.msg);
        // this.$router.push("./home");
      });
    }
  },
};
</script>

<style>
/* 清除所有元素内外边距 转换为c3盒子模型 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 清除input、button默认边框以及点击高亮 */
input,
button {
  outline: none;
  border: none;
}
/* 清除a标签默认下划线 颜色设置为#333 */
a {
  text-decoration: none;
  color: #333;
  margin: 1.25rem 0;
}
/* 页面整体样式 */
body {
  /* min-height: 100vh; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background: url("../assets/组 1257@2x.png") no-repeat fixed center;
  background-size: cover;
}
/* 盒子样式 */
.container {
  position: relative;
  width: 60vw;
  height: 70vh;
  background-color: #e9e9e9;
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.3),
    0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  overflow: hidden;
}
/* 将两个form表单设置绝对定位 */
.container-form {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.5s ease-in-out;
}
/* 设置注册页样式 */
.container-signup {
  left: 0;
  width: 50%;
  z-index: 2;
  opacity: 1;
}
/* 设置登录页样式 */
.container-signin {
  left: 0;
  width: 50%;
  z-index: 1;
  opacity: 0;
}
.container.switch  .container-signin{
  transform: translateX(100%);
  z-index: 5;
  opacity: 1;
}
.container.switch .container-signup {
  transform: translateX(100%);
  opacity: 0;
}
/* 设置覆盖层 */
.container-overlay {
  position: absolute;
  left: 50%;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
  z-index: 999;
}
/* 设置显示背景图 */
.overlay {
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: url("../assets/组 1257@2x.png") no-repeat fixed center;
  background-size: cover;
  transition: transform 0.5s ease-in-out;
  
}

.container.switch .container-overlay {
  transform: translateX(-100%);
}
.container.switch .overlay {
  transform: translateX(50%);
}
/* 设置切换按钮页样式 */
.overlay-slide {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  transition: transform 0.5s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}
.overlay-right {
  transform: translateX(0);
  right: 0;
}
.container.switch .overlay-left {
  transform: translateX(0);
}
.container.switch .overlay-right {
  transform: translateX(20%);
}
/* 设置表单内元素样式 */
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}
/* 设置input标签样式 */
.input {
  width: 100%;
  margin: 0.5rem 0;
  padding: 1rem;
}
/* 设置表单标题样式 */
.form-title {
  font-weight: 300;
  margin-bottom: 1rem;
}
/* 设置按钮样式 */
.submit {
  padding: 1rem 3rem;
  background-image: -webkit-linear-gradient(40deg, #0367a6 0%, #008997 70%);
  border-radius: 1rem;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  transition: transform 0.1s ease-in-out;
}

/* 只设置表单按钮的外边距 */
.form > .submit {
  margin-top: 1.5rem;
}
/* 设置按钮按下样式 */
.submit:active {
  transform: scale(0.95);
}
.aed{
  position: absolute;
  bottom: 0;
  right: 10px;
}

</style>


<style scoped>
	.selectDiy  /deep/ .el-select {
		width:140px;
		margin: 0 2%;
	}
	.selectDiy  /deep/ .el-input {
		width:140px;
		min-width:140px;
	}
</style>