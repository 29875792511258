<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>字段设置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="0">
        <el-col :span="8">
          <div class="fle">
            <el-select v-model="select" slot="prepend" placeholder="请选择">
              <el-option
                :label="item[1]"
                :value="item[0]"
                v-for="(item, index) in title1"
                :key="index"
              ></el-option>
            </el-select>
            <el-input
              placeholder="请输入内容"
              v-model="searchinput"
              class="input-with-select"
              clearable
              @clear="clear_search"
            >
            </el-input>

            <el-select
              v-model="select_int"
              slot="prepend"
              placeholder="请选择"
              v-show="int_show"
            >
              <el-option
                :key="item.value"
                :label="item.label"
                :value="item.value"
                v-for="item in int_options"
              ></el-option>
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button></div
        ></el-col>
        <el-col :span="4" :push="14">
          <el-button type="primary" @click="showAddPop">添加</el-button>
        </el-col>
      </el-row>
      <el-table
        class="item"
        :data="datas"
        border
        style="width: '500px'"
        @sort-change="sortchange"
      >
        <el-table-column
          :label="item[1]"
          sortable="custom"
          :property="item[0]"
          v-for="(item, index) in show_title"
          :key="index"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="item[2] === 'str'">
              {{ scope.row[scope.column.property] }}
            </div>
            <div v-if="item[2] === 'bool'">
              {{ scope.row[scope.column.property] ? "是" : "否" }}
            </div>
            <div v-if="item[2] === 'img'">
              <img
              v-for="(i,x) in scope.row[scope.column.property]"
              :key="x"
                :src="`${
                 i
                }`"
                width="40px"
                height="40px"
              />
            </div>
            <div v-if="item[2] === 'fk'">
              {{ scope.row[scope.column.property][4] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id, scope.row)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-document-copy"
              size="mini"
              @click="showcopyDialog(scope.row.id, scope.row)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removebyid(scope.row.id, scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        title="添加"
        :visible.sync="addDialogVisible"
        width="50%"
        @close="addDialogclosed"
      >
        <el-form
          :model="addForm"
          :rules="addFormrules"
          ref="addFormRef"
          label-width="200px"
        >
          <el-form-item
            :label="item[0][1]"
            v-for="(item, index) in addForm"
            :key="index"
          >
            <div v-if="item[0][2] === 'str'">
              <el-input
                type="textarea"
                v-model="item[1]"
                @input="change1($event)"
                :key="index"
              ></el-input>
            </div>
            <div v-if="item[0][2] === 'img' && item[0][3] === 'Text' ">
              <el-upload
                ref="upload"
                list-type="picture-card"
                 :on-remove="(e) => {
                    handleRemove(e, item);
                  }"
                action=""
                :http-request="
                  (e) => {
                    handleUploadForm1(e, item);
                  }
                "
                :limit="10"
                width="100px"
              >
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div v-if="item[0][2] === 'img'  && item[0][3] === 'String'">
              <el-upload
                ref="upload"
                list-type="picture-card"
                 :on-remove="(e) => {
                    handleRemove(e, item);
                  }"
                action=""
                :http-request="
                  (e) => {
                    handleUploadForm1(e, item);
                  }
                "
                :limit="1"
                
                width="100px"
              >
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div v-if="item[0][2] === 'fk'">
              <el-select
                v-model="addForm[item[0][0]][1]"
                @change="$forceUpdate()"
              >
                <el-option
                  v-for="item in item[2]"
                  :key="item[0]"
                  :label="item[1]"
                  :value="item[0]"
                >
                </el-option>
              </el-select>
            </div>
            <div v-if="item[0][2] === 'bool'">
              <el-select
                v-model="addForm[item[0][0]][1]"
                @change="$forceUpdate()"
              >
                <el-option
                  v-for="item in check_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="add()">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="修改"
        :visible.sync="editdialogVisible"
        width="50%"
        @close="editDialogClosed"
      >
        <el-form
          :model="show_edit_title"
          :rules="editfromrules"
          ref="editfromref"
          label-width="170px"
        >
          <el-form-item
            :label="item[0][1]"
            v-for="(item, index) in show_edit_title"
            :key="index"
          >
            <div v-if="item[0][2] === 'str'">
              <el-input
                type="textarea"
                v-model="item[1]"
                :key="index"
              ></el-input>
            </div>
            <div v-if="item[0][2] === 'img'  && item[0][3] === 'Text' ">
              <el-upload
                ref="upload"
                list-type="picture-card"
                 :on-remove="(e) => {
                    handleRemoveedit(e, item);
                  }"
                action=""
                :http-request="
                  (e) => {
                    handleUploadFormedit1(e, item);
                  }
                "
                :limit="10"
                width="100px"
                :file-list="item[2]"
              >
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div v-if="item[0][2] === 'img'  && item[0][3] === 'String'">
              <el-upload
                ref="upload"
                list-type="picture-card"
                 :on-remove="(e) => {
                    handleRemoveedit(e, item);
                  }"
                action=""
                :http-request="
                  (e) => {
                    handleUploadFormedit1(e, item);
                  }
                "
                :limit="1"
                width="100px"
                :file-list="item[2]"
              >
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div v-if="item[0][2] === 'fk'">
              <!-- :placeholder="show_edit_title[item][1][4]" -->
              <el-select v-model="show_edit_title[item[0][0]][1]">
                <el-option
                  v-for="item in item[2]"
                  :key="item[0]"
                  :label="item[1]"
                  :value="item[0]"
                >
                </el-option>
              </el-select>
            </div>
            <div v-if="item[0][2] === 'bool'">
              <el-select v-model="show_edit_title[item[0][0]][1]">
                <el-option
                  v-for="item in check_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editdialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editfrominfo">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="复制添加"
        :visible.sync="copydialogVisible"
        width="50%"
        @close="copyDialogClosed"
      >
        <el-form
          :model="show_copy_title"
          :rules="copyfromrules"
          ref="copyfromref"
          label-width="170px"
        >
          <el-form-item
            :label="item[0][1]"
            v-for="(item, index) in show_copy_title"
            :key="index"
          >
            <div v-if="item[0][2] === 'str'">
              <el-input
                type="textarea"
                v-model="item[1]"
                :key="index"
              ></el-input>
            </div>
                 <div v-if="item[0][2] === 'img'  && item[0][3] === 'Text'">
              <el-upload
                ref="upload"
                list-type="picture-card"
                :on-remove="(e) => {
                    handleRemovecopy(e, item);
                  }"
                action=""
                :http-request="
                  (e) => {
                    handleUploadFormcopy1(e, item);
                  }
                "
                :limit="10"
                width="100px"
                :file-list="item[2]"
              >
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div v-if="item[0][2] === 'img'  && item[0][3] === 'String'">
              <el-upload
                ref="upload"
                list-type="picture-card"
                :on-remove="(e) => {
                    handleRemovecopy(e, item);
                  }"
                action=""
                :http-request="
                  (e) => {
                    handleUploadFormcopy1(e, item);
                  }
                "
                :limit="1"
                width="100px"
                :file-list="item[2]"
              >
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div v-if="item[0][2] === 'fk'">
              <el-select v-model="copyfrom[item[0][0]][1]" :placeholder="fkval">
                <el-option
                  v-for="item in item[2]"
                  :key="item[0]"
                  :label="item[1]"
                  :value="item[0]"
                >
                </el-option>
              </el-select>
            </div>
            <div v-if="item[0][2] === 'bool'">
              <el-select v-model="copyfrom[item[0][0]][1]">
                <el-option
                  v-for="item in check_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="copydialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="copyfrominfo">确 定</el-button>
        </span>
      </el-dialog>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      check_options: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      int_options: [
        {
          value: true,
          label: "等于",
        },
        {
          value: false,
          label: "包括",
        },
      ],
      searchinput: "",
      select: "",
      select_int: "",
      fkval: "",
      defaultval: false,
      dialogVisible: false,
      data1: null,
      title1: null,
      editfromShow: false,
      copyfromrules: {},
      copydialogVisible: false,
      copyDialogVisible: false,
      fileList1: [],
      queryinfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
         sort_field: '',
        sort_desc: '',
        sys_id:'',
        search_field:'',
        int_operator:''
      },
      total: 0,
      options: [],
      editfromrules: {},
      editfrom: {},
      copyfrom: {},
      editdialogVisible: false,
      addDialogVisible: false,
      list: [],
      addForm: {
        // add_tab: "search_seed_introduce",
      },
      value: "",
      addFormrules: {},
      datas: [],
      addfr: {},
    };
  },
  created() {
    this.getlist();
    // this.get();
  },
  methods: {
     clear_search(){
      this.queryinfo.query=''
      this.getlist()
    },
    sortchange(e) {
         this.queryinfo.sort_field = e.prop
      this.queryinfo.sort_desc = e.order
      console.log(e, "eee");
      let params = {
        sort_field: e.prop,
        sort_desc: e.order,
      };
      this.$http({
        method: "post",
        url: "bmlist_branch/version_management/0900",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },

        data: params,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        this.title1 = res.data.title_full;

        this.datas = res.data.datas_full;

        this.total = res.data.top;
        console.log(res);
      });
      // console.log(params)
    },
   async showAddPop() {
    // this.addForm={z`}
      const arr = Object.keys(this.show_title);
      for (let i in this.show_title) {
        if (this.show_title[i][2] == "fk") {
          const { data: opt } = await this.$http.get(
            `bmfkselect/${this.show_title[i][3]}/${this.show_title[i][4]}/0900`
          );
          this.addForm[this.show_title[i][0]] = [this.show_title[i], "", opt];
        } else if (this.show_title[i][2] == "img"){
      this.addForm[this.show_title[i][0]] = [this.show_title[i], []];
        }
        else {
          this.addForm[this.show_title[i][0]] = [this.show_title[i], ""];
        }
      }
console.log(this.addForm)

      this.addDialogVisible = true;
    },
    async getlist() {
      const { data: res } = await this.$http.get(
        "bmlist_branch/version_management/0900",
        {
          params: this.queryinfo,
        }
      );

      this.title1 = res.title_full;
      //  this.addForm = res.title_full;
      let bj = Object.keys(this.title1);

      this.datas = res.datas_full;

      this.total = res.top;
      console.log(res)
    },
    handleSizeChange(newsize) {
      this.queryinfo.pagesize = newsize;
      this.getlist();
    },
    handleCurrentChange(newpage) {
      this.queryinfo.pagenum = newpage;
      this.getlist();
    },
    addDialogclosed() {
      this.$refs.addFormRef.resetFields();
    },
    add() {

      let params = {
        add_tab: "version_management",
      };
      for (let i in this.show_title) {
        params[this.show_title[i][0]] = this.addForm[this.show_title[i][0]][1];
      }
      
      this.$http({
        method: "post",
        url: "bmtabadd/0900/version_management",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },

        data: params,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
    
        this.getlist();
        this.$message(res.data);
      });
      this.addDialogVisible = false;
    },
    async removebyid(id) {
      // id=scope.$index + 1
     
      const confirmResult = await this.$confirm(
        "此操作将永久删除该条信息, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
     
      if (confirmResult != "confirm") {
        return this.$message.info("已取消删除");
      }
      this.$http({
        method: "post",
        url: "bmtabdel",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        data: {
          tab: "version_management",
          tid: id,
        },
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        this.getlist();
        this.$message(res.data);
      });
      this.addDialogVisible = false;
    },
    async showEditDialog(id, item) {
      this.editfromShow = true;
      this.editdialogVisible = true;

      const { data: res } = await this.$http.get(
        "bmtabedit/0900/" + id + "/version_management"
      );
      console.log(res);
      for (item in res) {
        if (res[item][0][2] == "img") {
          const imgs = [];
          if (res[item][1] && res[item][1].length && res[item][1][0]) {
           
            for(let i in res[item][1]){
              console.log(res[item][1][i])
               imgs.push({
              url: `${res[item][1][i]}`,
            });
            }
          }
          res[item][2] = imgs;
          console.log(imgs);
        } else if (res[item][0][2] == "fk") {
          this.fkval = res[item][1][4];

          var kf1 = res[item][1][0];
          var kf2 = res[item][1][1];
          console.log(res[item][1]);
          // console.log(kf2)
          // console.log(res[item], res[item][1][3], 79457);
          res[item][1] = Number(res[item][1][3]);

          const { data: opt } = await this.$http.get(
            `bmfkselect/${kf1}/${kf2}/0900`
          );
          res[item][2] = opt;
        }
      }

      this.editfrom = res;
    },
    async showcopyDialog(id, item) {
      this.copydialogVisible = true;
      const { data: res } = await this.$http.get(
        "bmtabedit/0900/" + id + "/version_management"
      );

      for (item in res) {
        if (res[item][0][2] == "img") {
          const imgs = [];
          if (res[item][1] && res[item][1].length && res[item][1][0]) {
           
            for(let i in res[item][1]){
              console.log(res[item][1][i])
               imgs.push({
              url: `${res[item][1][i]}`,
            });
            }
          }
          res[item][2] = imgs;
          console.log(imgs);
        } else if (res[item][0][2] == "fk") {
          this.fkval = res[item][1][4];

          var kf1 = res[item][1][0];
          var kf2 = res[item][1][1];
          console.log(res[item][1]);
          // console.log(kf2)
          // console.log(res[item], res[item][1][3], 79457);
          res[item][1] = Number(res[item][1][3]);

          const { data: opt } = await this.$http.get(
            `bmfkselect/${kf1}/${kf2}/0900`
          );
          res[item][2] = opt;
        }
      }
      this.copyfrom = res;
    },
    editDialogClosed() {
      this.$refs.editfromref.resetFields();
      this.fileList1 = [];
    },
    copyDialogClosed() {
      this.$refs.copyfromref.resetFields();
      this.fileList1 = [];
    },
    editfrominfo() {
      let params = {
        sid: "0900",
      };
      for (let i in this.title1) {
     
        if (this.title1[i][0] == "id") {
          params.tid = this.editfrom["id"][1];
        } else {
          params[this.title1[i][0]] = this.editfrom[this.title1[i][0]][1];
        }
      }
      this.$http({
        method: "post",
        url: "bmedit/version_management",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },

        data: params,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        this.getlist();
        this.$message(res.data);
      });
      this.editdialogVisible = false;
    },
    copyfrominfo() {
      let params = {
        sid: "0900",
      };
      for (let i in this.title1) {
        if (this.title1[i][0] == "id") {
          params.tid = this.copyfrom["id"][1];
        } else {
          params[this.title1[i][0]] = this.copyfrom[this.title1[i][0]][1];
        }
      }
      this.$http({
        method: "post",
        url: "bmtabadd/0900/version_management",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        data: params,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        this.getlist();
        this.$message(res.data);
      });

      this.copydialogVisible = false;
    },
async search() {
     this.queryinfo.sys_id = "0900"
       this.queryinfo.query = this.searchinput,
       this.queryinfo.search_field = this.select
       this.queryinfo.int_operator = this.select_int
      let params = {
        sys_id: "0900",
        query: this.searchinput,
        search_field: this.select,
        int_operator: this.select_int,
      };
      this.$http({
        method: "post",
        url: "/search_keyword",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        data: params,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      }).then((res) => {
        console.log(res);
        this.title1 = res.data.title_full;
        this.datas = res.data.datas_full;
        this.total = res.data.top;
        // this.getlist()
      });
    },
     handleUploadForm1(param, item) {
      console.log(12312312, param, item);
      const name = item[0][0];
      // console.log(this.filelist)
      let formData = new FormData();
      // 在formData中加入我们需要的参数
      // formData.append("img1", param.file);
      formData.append(name, param.file);
      formData.append("tab", "version_management");
      console.log(formData);
      // 向后端发送数据
      this.$http
        .post("/mup_file_img", formData)
        .then((res) => {
          console.log(res);

          this.addForm[name][1].push(res.data.meta.url)
          console.log(this.addForm)
        });
    },
    handleUploadFormedit1(param, item) {
      console.log(12312312, param, item);
      const name = item[0][0];
      // console.log(this.filelist)
      let formData = new FormData();
      // 在formData中加入我们需要的参数
      // formData.append("img1", param.file);
      formData.append(name, param.file);
      formData.append("tab", "version_management");
      console.log(formData);
      // 向后端发送数据
      this.$http
        .post("/mup_file_img", formData)
        .then((res) => {
          console.log(res);
          console.log(this.editfrom, name, 749);

          // this.editfrom[name][1] = res.data.meta.url;
          this.editfrom[name][1].push(res.data.meta.url)
        });
    },
    handleUploadFormcopy1(param, item) {
      const name = item[0][0];
      
      let formData = new FormData();
      // 在formData中加入我们需要的参数
      // formData.append("img1", param.file);
      formData.append(name, param.file);
      formData.append("tab", "version_management");
     
      // 向后端发送数据
      this.$http
        .post("/mup_file_img", formData)
        .then((res) => {
          console.log(res);

          // this.copyfrom[name][1] = res.data.meta.url;
          this.copyfrom[name][1].push(res.data.meta.url)

        });
    },

    change1(e) {
      this.$forceUpdate();
    },
       handleRemove(file,item,) {
        // console.log(file,'remove');
        let romoveitem = `/static/files/version_management/${file.name}`
        // console.log(romoveitem);
        let removearr = this.addForm[item[0][0]][1]
      const removeindex =   removearr.findIndex((item,index)=>{
          return item = romoveitem
        })
        console.log(removeindex);
        this.addForm[item[0][0]][1].splice(removeindex,1)
        console.log(this.addForm)
let params = {
  del_file:true,
  file_path:romoveitem
};
console.log(params);
this.$http({
        method: "post",
        url: "del_file_img",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },

        data: params,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      })
      },
     handleRemoveedit(file,item,) {
      if(file.name){
console.log(file.name,'remove');
let romoveitem = `/static/files/version_management/${file.name}`
        // console.log(romoveitem);
        let removearr = this.editfrom[item[0][0]][1]
      const removeindex =   removearr.findIndex((item,index)=>{
          return item = romoveitem
        })
        console.log(removeindex);
        this.editfrom[item[0][0]][1].splice(removeindex,1)
        console.log(this.editfrom)
    let params = {
  del_file:true,
  file_path:romoveitem
};
console.log(params);
this.$http({
        method: "post",
        url: "del_file_img",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },

        data: params,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      })

      }else{
        console.log(file.url)
        console.log(this.editfrom[item[0][0]][1])
        let removearr = this.editfrom[item[0][0]][1]
      const removeindex =   removearr.findIndex((item,index)=>{
          return file.url.indexOf(item) != -1
        })
        this.editfrom[item[0][0]][1].splice(removeindex,1)
        console.log(removeindex);
let params = {
  del_file:true,
  file_path:file.url
};
console.log(params);
this.$http({
        method: "post",
        url: "del_file_img",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },

        data: params,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      })
      }
        
        
      },   
 handleRemovecopy(file,item,) {
       if(file.name){
console.log(file.name,'remove');
let romoveitem = `/static/files/version_management/${file.name}`
        // console.log(romoveitem);
        let removearr = this.copyfrom[item[0][0]][1]
      const removeindex =   removearr.findIndex((item,index)=>{
          return item = romoveitem
        })
        console.log(removeindex);
        this.copyfrom[item[0][0]][1].splice(removeindex,1)
        console.log(this.copyfrom)
    let params = {
  del_file:true,
  file_path:romoveitem
};
console.log(params);
this.$http({
        method: "post",
        url: "del_file_img",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },

        data: params,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      })

      }else{
        console.log(file.url)
        console.log(this.copyfrom[item[0][0]][1])
        let removearr = this.copyfrom[item[0][0]][1]
      const removeindex =   removearr.findIndex((item,index)=>{
          return file.url.indexOf(item) != -1
        })
        this.copyfrom[item[0][0]][1].splice(removeindex,1)
        console.log(removeindex);
let params = {
  del_file:true,
  file_path:file.url
};
console.log(params);
this.$http({
        method: "post",
        url: "del_file_img",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },

        data: params,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
      })
      }
      },
  },
    watch:{
    select(a,b){    
      this.searchinput = ''
    }
  },
  computed: {
    show_title() {
      let show_title = {};
      for (let item in this.title1) {
        if (this.title1[item][5] == "true") {
          show_title[item] = this.title1[item];
        }
      }
      return show_title;
    },
    show_edit_title() {
      console.log(2, this.editfrom);
      let show_edit_title = {};
      for (let item in this.editfrom) {
        if (this.editfrom[item][0][5] == "true") {
          show_edit_title[item] = this.editfrom[item];
        }
      }
      console.log(show_edit_title, "234");
      return show_edit_title;
    },
    show_copy_title() {
      let show_copy_title = {};
      for (let item in this.copyfrom) {
        if (this.copyfrom[item][0][5] == "true") {
          show_copy_title[item] = this.copyfrom[item];
        }
      }
      return show_copy_title;
    },
    int_show() {
      // console.log(this.title1[this.select][3])
      if (this.select !== "") {
        console.log(this.title1[this.select][3]);
        return (
          this.title1[this.select][3] === "BigInteger" ||
          this.title1[this.select][3] === "Integer" ||
          this.title1[this.select][3] === "Floatprecision" ||
          this.title1[this.select][3] === "Float"
        );
      }
      return false;
    },
  },
};
</script>

<style>
</style>
