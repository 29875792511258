import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './plugins/element.js'
// import { MessageBox } from 'element-ui';
import Element from 'element-ui';
Vue.use(Element, { size: 'small', zIndex: 3000 });
import echarts from 'echarts'
Vue.prototype.$echarts =echarts
// console.log(this.$route);
// 导入全局样式
import './assets/css/global.css'
// import '../src/assets/css/all.css'
import "video.js/dist/video-js.css"; // 引入video.js的css

import '../src/assets/css/iconfont.css'
import axios from 'axios'
// import axios2 from 'axios'

//axios.defaults.baseURL = 'http://inside.cloudcyz.com:8000/'
//axios.defaults.baseURL = 'https://inside.cloudcyz.com/'
axios.defaults.baseURL = '/rx_yuncu'
// axios2.defaults.baseURL = '/api'

Vue.prototype.$http = axios
// Vue.prototype.$http2 = axios2
Vue.config.productionTip = false
// Vue.component(MessageBox.name, MessageBox,);
// // Vue.use(Message)
// Vue.prototype.$alert = MessageBox.alert;
// Vue.prototype.$msgbox = MessageBox;
/**
 * 全局引用第三方组件
 */
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
Vue.component('treeselect',Treeselect)


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
