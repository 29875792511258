import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import login1 from '../components/login1.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/Welcome.vue'
import dasuan_contrast_info from '../components/dasuan/dasuan_contrast_info.vue'
import dasuanbz_info from '../components/dasuan/dasuanbz_info.vue'
import garlic_product_man from '../components/dasuan/garlic_product_man.vue'
import garlic_ctl_dise from '../components/dasuan/garlic_ctl_dise.vue'
import technical_features from '../components/dasuan/technical_features.vue'
import prevention_cure from '../components/dasuan/prevention_cure.vue'
import menu from '../components/system/menu.vue'
import sub_menu from '../components/system/sub_menu.vue'
import tencet_sms_stutas from '../components/system/tencet_sms_stutas.vue'
// import crops_admin from '../components/system/crops_admin.vue'
import area_base from '../components/yunchu.vue/area_base.vue'
import Vedio_info from '../components/yunchu.vue/Vedio_info.vue'
import warn_def from "../components/yunchu.vue/warn_def.vue"
import warn_level from "../components/yunchu.vue/warn_level.vue"
import bson_tips from "../components/yunchu.vue/bson_tips.vue"
import sys_column from "../components/system/sys_column.vue"
import sys_info from "../components/system/sys_info.vue"
import version_management from "../components/system/version_management.vue" //version_manager
import text from "../components/dasuan/text.vue"
import foot_menu from "../components/menu/foot_menu.vue"
import top_menu from "../components/menu/top_menu.vue"
import search_seed from "../components/dasuan/search_seed.vue"
import search_seed_cls from "../components/dasuan/search_seed_cls.vue"
import search_seed_introduce from "../components/dasuan/search_seed_introduce.vue"
import zhongzhi_menu from "../components/menu/zhongzhi_menu.vue"
import top_page from "../components/menu/top_page.vue"
import tianj_man from "../components/yunchu.vue/tianj_man.vue"
import seed_treatment from "../components/dasuan/seed_treatment.vue"
import agricult_product_meun from "../components/menu/agricult_product_meun.vue"
import expert_consult from "../components/Disease_insect_query/expert_consult.vue"
import crop_consult from "../components/Disease_insect_query/crop_consult.vue"
import consult_release from "../components/Disease_insect_query/consult_release.vue"
import crop_offline_ses from "../components/Disease_insect_query/crop_offline_ses.vue"
import crop_offline_consult from "../components/Disease_insect_query/crop_offline_consult.vue"
import agricult_product from "../components/agricult/agricult_product.vue"
import expert_group from "../components/Disease_insect_query/expert_group.vue"
import rixin_admin from "../components/system/rixin_admin.vue"
import crops_admin from "../components/system/crops_admin.vue"
import farmland_yuncu from "../components/yunchu.vue/farmland_yuncu.vue"
import area_crops_m from "../components/yunchu.vue/area_crops_m.vue"
import yc_broadcast from "../components/menu/yc_broadcast.vue"
import binc_result from "../components/Disease_insect_query/binc_result.vue"
import bc_search_menu from "../components/Disease_insect_query/bc_search_menu.vue"
import bch_en_chn from "../components/Disease_insect_query/bch_en_chn.vue"
import crop_info from "../components/crops/crop_info.vue"
import crop_pests_diseases from "../components/crops/crop_pests_diseases"
import nongyou_circle_meun from "../components/nongyou_circle_meun/nongyou_circle_meun.vue"
import agricult_product_inout from "../components/nongyou_circle_meun/agricult_product_inout.vue"
import new_dynamic from "../components/nongyou_circle_meun/new_dynamic.vue"
import m2m_crops_dz from "../components/nongyou_circle_meun/m2m_crops_dz.vue"
import agr_pro_details from "../components/agricult/agr_pro_details.vue"
import yuncu_show from "../components/system/yuncu_show.vue"
import agricultural_drug from "../components/agricult/agricultural_drug.vue"
import prevent_treat_obj from "../components/agricult/prevent_treat_obj.vue"
import sensor_attri from "../components/Internet_of_things_devices/sensor_attri.vue"
import sensor_type from "../components/Internet_of_things_devices/sensor_type.vue"
import iot_info from "../components/Internet_of_things_devices/iot_info.vue"
import iot_add_field from "../components/Internet_of_things_devices/iot_add_field.vue"
import soil_sensor from "../components/Internet_of_things_devices/soil_sensor.vue"
import wind_sensor from "../components/Internet_of_things_devices/wind_sensor.vue"
import base_line_meun from '../components/yunchu.vue/base_line_meun'
//import auth_col from '../components/user/auth_col.vue'
import auth_tab from '../components/user/auth_tab.vue'
import role from '../components/user/role.vue'
import agricult_product_show from '../components/agricult/agricult_product_show'
import auth_group from '../components/yunchu.vue/auth_group'
import rice_seed_mode from '../components/rice/rice_seed_mode'
import rice_seed_quality from '../components/rice/rice_seed_quality'
import rice_search_seed from '../components/rice/rice_search_seed'
import rice_seed from '../components/rice/rice_seed'
import m2m_bc_drug from '../components/Bidirectiona_table/m2m_bc_drug'
import m2m_agr_pro_details_drug from '../components/Bidirectiona_table/m2m_agr_pro_details_drug'
import three_menu from '../components/system/three_menu'
import text1 from '../components/text'
import Large_screen from '../components/Large_screen/Large_screen' 
import top_page_msg from '../components/system/top_page_msg' 
// yc_shop
// import t_shop_goods from '../components/yc_shop_admin/t_shop_goods'
import t_shop_goods from '../components/goods'
import t_shop_cart from '../components/cart'
import t_shop_favorite from '../components/favorite'
import t_shop_order from '../components/order'
import orderDetail from '../components/order/orderDetail'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
    { path: '/login', component: login1 },
    {
      path: '/home', component: Home,
      redirect: '/welcome'
      , children: [
        { path: '/welcome', component: Welcome },
        { path: '/dasuan_contrast_info', component: dasuan_contrast_info },
        { path: '/dasuanbz_info', component: dasuanbz_info },
        { path: '/garlic_product_man', component: garlic_product_man },
        { path: '/garlic_ctl_dise', component: garlic_ctl_dise },
        { path: '/technical_features', component: technical_features },
        { path: '/prevention_cure', component: prevention_cure },
        { path: '/menu', component: menu },
        { path: '/sub_menu', component: sub_menu },
        // {path:'/crops_admin',component:crops_admin},
        { path: '/area_base', component: area_base },
        { path: '/Vedio_info', component: Vedio_info },
        { path: '/warn_def', component: warn_def },
        { path: '/warn_level', component: warn_level },
        { path: '/bson_tips', component: bson_tips },
        { path: '/crops_admin', component: crops_admin },
        { path: '/sys_column', component: sys_column },
        { path: '/sys_info', component: sys_info },
        { path: '/version_management', component: version_management },  //version_manage
        { path: '/tencet_sms_stutas', component: tencet_sms_stutas },  //sms_callback
        { path: '/text', component: text },
        { path: '/foot_menu', component: foot_menu },
        { path: '/top_menu', component: top_menu },
        { path: '/search_seed', component: search_seed },
        { path: '/search_seed_cls', component: search_seed_cls },
        { path: '/search_seed_introduce', component: search_seed_introduce },
        { path: '/zhongzhi_menu', component: zhongzhi_menu },
        { path: '/top_page', component: top_page },
        { path: '/tianj_man', component: tianj_man },
        { path: '/seed_treatment', component: seed_treatment },
        { path: '/agricult_product_meun', component: agricult_product_meun },
        { path: '/expert_consult', component: expert_consult },
        { path: '/crop_consult', component: crop_consult },
        { path: '/consult_release', component: consult_release },
        { path: '/crop_offline_ses', component: crop_offline_ses },
        { path: '/crop_offline_consult', component: crop_offline_consult },
        { path: '/agricult_product', component: agricult_product },
        { path: '/expert_group', component: expert_group },
        { path: '/rixin_admin', component: rixin_admin },
        { path: '/farmland_yuncu', component: farmland_yuncu },
	{ path: '/area_crops_m',component: area_crops_m},
        { path: '/yc_broadcast', component: yc_broadcast },
        { path: '/binc_result', component: binc_result },
        { path: '/bc_search_menu', component: bc_search_menu },
        { path: '/bch_en_chn', component: bch_en_chn },
        { path: '/crop_info', component: crop_info },
        { path: '/crop_pests_diseases', component: crop_pests_diseases },
        { path: '/nongyou_circle_meun', component: nongyou_circle_meun },
        { path: '/agricult_product_inout', component: agricult_product_inout },
        { path: '/new_dynamic', component: new_dynamic },
        { path: '/m2m_crops_dz', component: m2m_crops_dz },
        { path: '/agr_pro_details', component: agr_pro_details },
        { path: '/yuncu_show', component: yuncu_show },
        { path: '/agricultural_drug', component: agricultural_drug },
        { path: '/prevent_treat_obj', component: prevent_treat_obj },
        { path: '/sensor_attri', component: sensor_attri },
        { path: '/sensor_type', component: sensor_type },
        { path: '/iot_info', component: iot_info },
        { path: '/iot_add_field', component: iot_add_field },
        { path: '/soil_sensor', component: soil_sensor },
        { path: '/wind_sensor', component: wind_sensor },
        { path: '/base_line_meun', component: base_line_meun },
     //   { path: '/auth_col', component: auth_col },
        { path: '/auth_tab', component: auth_tab },
        { path: '/role', component: role },
        { path: '/agricult_product_show', component: agricult_product_show },
        { path: '/auth_group', component: auth_group },
        { path: '/rice_seed_mode', component: rice_seed_mode },
        { path: '/rice_seed_quality', component: rice_seed_quality },
        { path: '/rice_search_seed', component: rice_search_seed },
        { path: '/rice_seed', component: rice_seed },
        { path: '/m2m_bc_drug', component: m2m_bc_drug },
        { path: '/m2m_agr_pro_details_drug', component: m2m_agr_pro_details_drug },
        { path: '/three_menu', component: three_menu },
        { path: '/top_page_msg', component: top_page_msg },
        { path: '/text1', component: text1 },
//yc_shop
	// { path: '/t_shop_goods',component: t_shop_goods},
	{ path: '/t_shop_goods',component: t_shop_goods},
	{ path: '/t_shop_cart', component: t_shop_cart },
	{ path: '/t_shop_favorite', component: t_shop_favorite },
	{ path: '/t_shop_order', component: t_shop_order },
	{ path: '/orderDetail', component: orderDetail },
      ]
    },
    { path: '/Large_screen', component: Large_screen },

]

const router = new VueRouter({
  routes
})
router.beforeEach((to,from,next)=>{
  console.log(to.path, from.path)
  const topath = to.path
        if(to.path === '/login') 
        return next()

        if(to.path === '/Large_screen') 
        return next()
        const tokenstr  = window.sessionStorage.getItem('TOKEN')
        if(!tokenstr)
        return next('/login')
        
        next()
        
})
export default router
